export default function memberStreak({ gender, level }) {
  if (gender === 'female') {
    return (
      <svg
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        style={{ enableBackground: 'new 0 0 500 500' }}
      >
        <style type="text/css">
          {`.st0{fill:#1D1D1B;}
	.st1{fill:url(#SVGID_1_);}
	.st2{fill:url(#SVGID_2_);}
	.st3{fill:url(#SVGID_3_);}
	.st4{fill:url(#SVGID_4_);}
	.st5{opacity:0.6;clip-path:url(#SVGID_6_);fill:url(#SVGID_7_);}
	.st6{fill:none;stroke:url(#SVGID_8_);stroke-width:3;stroke-miterlimit:10;}
	.st7{fill:none;stroke:#DADADA;stroke-width:2;stroke-miterlimit:10;}
	.st8{fill:url(#SVGID_9_);}
	.st9{opacity:0.07;clip-path:url(#SVGID_11_);}
	.st10{fill:#FFFFFF;}
	.st11{opacity:0.4;clip-path:url(#SVGID_11_);fill:url(#SVGID_12_);}
	.st12{fill:none;stroke:url(#SVGID_13_);stroke-width:3;stroke-miterlimit:10;}
	.st13{fill:#DADADA;}
	.st14{opacity:0.14;fill:#DADADA;}
	.st15{fill:none;stroke:url(#SVGID_14_);stroke-width:3;stroke-miterlimit:10;}
	.st16{fill:none;stroke:url(#SVGID_15_);stroke-width:3;stroke-miterlimit:10;}
	.st17{fill:none;stroke:url(#SVGID_16_);stroke-width:3;stroke-miterlimit:10;}
	.st18{fill:none;stroke:url(#SVGID_17_);stroke-width:3;stroke-miterlimit:10;}
	.st19{fill:none;stroke:url(#SVGID_18_);stroke-width:3;stroke-miterlimit:10;}
	.st20{fill:none;stroke:url(#SVGID_19_);stroke-width:3;stroke-miterlimit:10;}
	.st21{fill:none;stroke:url(#SVGID_20_);stroke-width:3;stroke-miterlimit:10;}
	.st22{fill:none;stroke:url(#SVGID_21_);stroke-width:3;stroke-miterlimit:10;}
	.st23{fill:none;stroke:url(#SVGID_22_);stroke-width:3;stroke-miterlimit:10;}
	.st24{fill:none;stroke:url(#SVGID_23_);stroke-width:3;stroke-miterlimit:10;}
	.st25{fill:none;stroke:url(#SVGID_24_);stroke-width:3;stroke-miterlimit:10;}
	.st26{fill:none;stroke:url(#SVGID_25_);stroke-width:3;stroke-miterlimit:10;}
	.st27{fill:none;stroke:url(#SVGID_26_);stroke-width:3;stroke-miterlimit:10;}
	.st28{fill:none;stroke:url(#SVGID_27_);stroke-width:3;stroke-miterlimit:10;}
	.st29{fill:none;stroke:url(#SVGID_28_);stroke-width:3;stroke-miterlimit:10;}
	.st30{fill:none;stroke:url(#SVGID_29_);stroke-width:3;stroke-miterlimit:10;}
	.st31{fill:none;stroke:url(#SVGID_30_);stroke-width:3;stroke-miterlimit:10;}
	.st32{fill:none;stroke:url(#SVGID_31_);stroke-width:3;stroke-miterlimit:10;}
	.st33{fill:none;stroke:url(#SVGID_32_);stroke-width:3;stroke-miterlimit:10;}
	.st34{fill:none;stroke:url(#SVGID_33_);stroke-width:3;stroke-miterlimit:10;}
	.st35{fill:none;stroke:url(#SVGID_34_);stroke-width:3;stroke-miterlimit:10;}
	.st36{fill:none;stroke:url(#SVGID_35_);stroke-width:3;stroke-miterlimit:10;}
	.st37{fill:none;stroke:url(#SVGID_36_);stroke-width:3;stroke-miterlimit:10;}
	.st38{fill:none;stroke:url(#SVGID_37_);stroke-width:3;stroke-miterlimit:10;}
	.st39{fill:none;stroke:url(#SVGID_38_);stroke-width:3;stroke-miterlimit:10;}
	.st40{fill:none;stroke:url(#SVGID_39_);stroke-width:3;stroke-miterlimit:10;}
	.st41{fill:none;stroke:url(#SVGID_40_);stroke-width:3;stroke-miterlimit:10;}
	.st42{fill:none;stroke:url(#SVGID_41_);stroke-width:3;stroke-miterlimit:10;}
	.st43{fill:none;stroke:url(#SVGID_42_);stroke-width:3;stroke-miterlimit:10;}
	.st44{fill:none;stroke:url(#SVGID_43_);stroke-width:3;stroke-miterlimit:10;}
	.st45{fill:none;stroke:url(#SVGID_44_);stroke-width:3;stroke-miterlimit:10;}
	.st46{fill:none;stroke:url(#SVGID_45_);stroke-width:3;stroke-miterlimit:10;}
	.st47{fill:none;stroke:url(#SVGID_46_);stroke-width:3;stroke-miterlimit:10;}
	.st48{fill:none;stroke:url(#SVGID_47_);stroke-width:3;stroke-miterlimit:10;}
	.st49{fill:none;stroke:url(#SVGID_48_);stroke-width:3;stroke-miterlimit:10;}
	.st50{fill:none;stroke:url(#SVGID_49_);stroke-width:3;stroke-miterlimit:10;}
	.st51{fill:none;stroke:url(#SVGID_50_);stroke-width:3;stroke-miterlimit:10;}
	.st52{fill:none;stroke:url(#SVGID_51_);stroke-width:3;stroke-miterlimit:10;}
	.st53{fill:none;stroke:url(#SVGID_52_);stroke-width:3;stroke-miterlimit:10;}
	.st54{fill:none;stroke:url(#SVGID_53_);stroke-width:3;stroke-miterlimit:10;}
	.st55{fill:none;stroke:url(#SVGID_54_);stroke-width:3;stroke-miterlimit:10;}
	.st56{fill:none;stroke:url(#SVGID_55_);stroke-width:3;stroke-miterlimit:10;}
	.st57{fill:none;stroke:url(#SVGID_56_);stroke-width:3;stroke-miterlimit:10;}
	.st58{fill:none;stroke:url(#SVGID_57_);stroke-width:3;stroke-miterlimit:10;}
	.st59{fill:none;stroke:url(#SVGID_58_);stroke-width:3;stroke-miterlimit:10;}
	.st60{fill:none;stroke:url(#SVGID_59_);stroke-width:3;stroke-miterlimit:10;}
	.st61{fill:none;stroke:url(#SVGID_60_);stroke-width:3;stroke-miterlimit:10;}
	.st62{fill:none;stroke:url(#SVGID_61_);stroke-width:3;stroke-miterlimit:10;}
	.st63{fill:none;stroke:url(#SVGID_62_);stroke-width:3;stroke-miterlimit:10;}
	.st64{fill:none;stroke:url(#SVGID_63_);stroke-width:3;stroke-miterlimit:10;}
	.st65{fill:none;stroke:url(#SVGID_64_);stroke-width:3;stroke-miterlimit:10;}
	.st66{fill:none;stroke:url(#SVGID_65_);stroke-width:3;stroke-miterlimit:10;}
	.st67{fill:none;stroke:url(#SVGID_66_);stroke-width:3;stroke-miterlimit:10;}
	.st68{fill:none;stroke:url(#SVGID_67_);stroke-width:3;stroke-miterlimit:10;}
	.st69{fill:none;stroke:url(#SVGID_68_);stroke-width:3;stroke-miterlimit:10;}
	.st70{fill:none;stroke:url(#SVGID_69_);stroke-width:3;stroke-miterlimit:10;}
	.st71{fill:none;stroke:url(#SVGID_70_);stroke-width:3;stroke-miterlimit:10;}
	.st72{clip-path:url(#SVGID_72_);}
	.st73{fill:none;stroke:url(#SVGID_73_);stroke-width:0.5;stroke-miterlimit:10;}
	.st74{fill:none;stroke:url(#SVGID_74_);stroke-width:0.5;stroke-miterlimit:10;}
	.st75{fill:none;stroke:url(#SVGID_75_);stroke-width:0.5;stroke-miterlimit:10;}
	.st76{fill:none;stroke:url(#SVGID_76_);stroke-width:0.5;stroke-miterlimit:10;}
	.st77{fill:none;stroke:url(#SVGID_77_);stroke-width:0.5;stroke-miterlimit:10;}
	.st78{fill:none;stroke:url(#SVGID_78_);stroke-width:0.5;stroke-miterlimit:10;}
	.st79{fill:none;stroke:url(#SVGID_79_);stroke-width:0.5;stroke-miterlimit:10;}
	.st80{fill:none;stroke:url(#SVGID_80_);stroke-width:0.5;stroke-miterlimit:10;}
	.st81{fill:none;stroke:url(#SVGID_81_);stroke-width:0.5;stroke-miterlimit:10;}
	.st82{fill:none;stroke:url(#SVGID_82_);stroke-width:0.5;stroke-miterlimit:10;}
	.st83{fill:none;stroke:url(#SVGID_83_);stroke-width:0.5;stroke-miterlimit:10;}
	.st84{fill:none;stroke:url(#SVGID_84_);stroke-width:0.5;stroke-miterlimit:10;}
	.st85{fill:none;stroke:url(#SVGID_85_);stroke-width:0.5;stroke-miterlimit:10;}
	.st86{fill:none;stroke:url(#SVGID_86_);stroke-width:0.5;stroke-miterlimit:10;}
	.st87{fill:none;stroke:url(#SVGID_87_);stroke-width:0.5;stroke-miterlimit:10;}
	.st88{fill:none;stroke:url(#SVGID_88_);stroke-width:0.5;stroke-miterlimit:10;}
	.st89{fill:none;stroke:url(#SVGID_89_);stroke-width:0.5;stroke-miterlimit:10;}
	.st90{fill:none;stroke:url(#SVGID_90_);stroke-width:0.5;stroke-miterlimit:10;}
	.st91{fill:none;stroke:url(#SVGID_91_);stroke-width:0.5;stroke-miterlimit:10;}
	.st92{fill:none;stroke:url(#SVGID_92_);stroke-width:0.5;stroke-miterlimit:10;}
	.st93{fill:none;stroke:url(#SVGID_93_);stroke-width:0.5;stroke-miterlimit:10;}
	.st94{fill:none;stroke:url(#SVGID_94_);stroke-width:0.5;stroke-miterlimit:10;}
	.st95{fill:none;stroke:url(#SVGID_95_);stroke-width:0.5;stroke-miterlimit:10;}
	.st96{fill:none;stroke:url(#SVGID_96_);stroke-width:0.5;stroke-miterlimit:10;}
	.st97{fill:none;stroke:url(#SVGID_97_);stroke-width:0.5;stroke-miterlimit:10;}
	.st98{fill:none;stroke:url(#SVGID_98_);stroke-width:0.5;stroke-miterlimit:10;}
	.st99{fill:none;stroke:url(#SVGID_99_);stroke-width:0.5;stroke-miterlimit:10;}
	.st100{fill:none;stroke:url(#SVGID_100_);stroke-width:0.5;stroke-miterlimit:10;}
	.st101{fill:none;stroke:url(#SVGID_101_);stroke-width:0.5;stroke-miterlimit:10;}
	.st102{fill:none;stroke:url(#SVGID_102_);stroke-width:0.5;stroke-miterlimit:10;}
	.st103{fill:none;stroke:url(#SVGID_103_);stroke-width:0.5;stroke-miterlimit:10;}
	.st104{fill:none;stroke:url(#SVGID_104_);stroke-width:0.5;stroke-miterlimit:10;}
	.st105{fill:none;stroke:url(#SVGID_105_);stroke-width:0.5;stroke-miterlimit:10;}
	.st106{fill:none;stroke:url(#SVGID_106_);stroke-width:0.5;stroke-miterlimit:10;}
	.st107{fill:none;stroke:url(#SVGID_107_);stroke-width:0.5;stroke-miterlimit:10;}
	.st108{fill:none;stroke:url(#SVGID_108_);stroke-width:0.5;stroke-miterlimit:10;}
	.st109{fill:none;stroke:url(#SVGID_109_);stroke-width:0.5;stroke-miterlimit:10;}
	.st110{fill:none;stroke:url(#SVGID_110_);stroke-width:0.5;stroke-miterlimit:10;}
	.st111{fill:none;stroke:url(#SVGID_111_);stroke-width:0.5;stroke-miterlimit:10;}
	.st112{fill:none;stroke:url(#SVGID_112_);stroke-width:0.5;stroke-miterlimit:10;}
	.st113{fill:none;stroke:url(#SVGID_113_);stroke-width:0.5;stroke-miterlimit:10;}
	.st114{fill:none;stroke:url(#SVGID_114_);stroke-width:0.5;stroke-miterlimit:10;}
	.st115{clip-path:url(#SVGID_116_);}
	.st116{fill:none;stroke:url(#SVGID_117_);stroke-width:2;stroke-miterlimit:10;}
	.st117{fill:none;stroke:url(#SVGID_118_);stroke-width:2;stroke-miterlimit:10;}
	.st118{fill:none;stroke:url(#SVGID_119_);stroke-width:2;stroke-miterlimit:10;}
	.st119{fill:none;stroke:url(#SVGID_120_);stroke-width:2;stroke-miterlimit:10;}
	.st120{fill:none;stroke:url(#SVGID_121_);stroke-width:2;stroke-miterlimit:10;}
	.st121{fill:none;stroke:url(#SVGID_122_);stroke-width:2;stroke-miterlimit:10;}
	.st122{fill:none;stroke:url(#SVGID_123_);stroke-width:2;stroke-miterlimit:10;}
	.st123{fill:none;stroke:url(#SVGID_124_);stroke-width:2;stroke-miterlimit:10;}
	.st124{fill:none;stroke:url(#SVGID_125_);stroke-width:2;stroke-miterlimit:10;}
	.st125{fill:none;stroke:url(#SVGID_126_);stroke-width:2;stroke-miterlimit:10;}
	.st126{fill:url(#SVGID_127_);}
	.st127{opacity:0.6;clip-path:url(#SVGID_129_);fill:url(#SVGID_130_);}
	.st128{fill:none;stroke:url(#SVGID_131_);stroke-width:3;stroke-miterlimit:10;}
	.st129{fill:url(#SVGID_132_);}
	.st130{fill:url(#SVGID_133_);}
	.st131{fill:url(#SVGID_134_);}
	.st132{clip-path:url(#SVGID_136_);}
	.st133{fill:none;stroke:url(#SVGID_137_);stroke-width:0.5;stroke-miterlimit:10;}
	.st134{fill:none;stroke:url(#SVGID_138_);stroke-width:0.5;stroke-miterlimit:10;}
	.st135{fill:none;stroke:url(#SVGID_139_);stroke-width:0.5;stroke-miterlimit:10;}
	.st136{fill:none;stroke:url(#SVGID_140_);stroke-width:0.5;stroke-miterlimit:10;}
	.st137{fill:none;stroke:url(#SVGID_141_);stroke-width:0.5;stroke-miterlimit:10;}
	.st138{fill:none;stroke:url(#SVGID_142_);stroke-width:0.5;stroke-miterlimit:10;}
	.st139{fill:none;stroke:url(#SVGID_143_);stroke-width:0.5;stroke-miterlimit:10;}
	.st140{fill:none;stroke:url(#SVGID_144_);stroke-width:0.5;stroke-miterlimit:10;}
	.st141{fill:none;stroke:url(#SVGID_145_);stroke-width:0.5;stroke-miterlimit:10;}
	.st142{fill:none;stroke:url(#SVGID_146_);stroke-width:0.5;stroke-miterlimit:10;}
	.st143{fill:none;stroke:url(#SVGID_147_);stroke-width:0.5;stroke-miterlimit:10;}
	.st144{fill:none;stroke:url(#SVGID_148_);stroke-width:0.5;stroke-miterlimit:10;}
	.st145{fill:none;stroke:url(#SVGID_149_);stroke-width:0.5;stroke-miterlimit:10;}
	.st146{fill:none;stroke:url(#SVGID_150_);stroke-width:0.5;stroke-miterlimit:10;}
	.st147{fill:none;stroke:url(#SVGID_151_);stroke-width:0.5;stroke-miterlimit:10;}
	.st148{fill:none;stroke:url(#SVGID_152_);stroke-width:0.5;stroke-miterlimit:10;}
	.st149{fill:none;stroke:url(#SVGID_153_);stroke-width:0.5;stroke-miterlimit:10;}
	.st150{fill:none;stroke:url(#SVGID_154_);stroke-width:0.5;stroke-miterlimit:10;}
	.st151{fill:none;stroke:url(#SVGID_155_);stroke-width:0.5;stroke-miterlimit:10;}
	.st152{fill:none;stroke:url(#SVGID_156_);stroke-width:0.5;stroke-miterlimit:10;}
	.st153{fill:none;stroke:url(#SVGID_157_);stroke-width:0.5;stroke-miterlimit:10;}
	.st154{fill:none;stroke:url(#SVGID_158_);stroke-width:0.5;stroke-miterlimit:10;}
	.st155{fill:none;stroke:url(#SVGID_159_);stroke-width:0.5;stroke-miterlimit:10;}
	.st156{fill:none;stroke:url(#SVGID_160_);stroke-width:0.5;stroke-miterlimit:10;}
	.st157{fill:none;stroke:url(#SVGID_161_);stroke-width:0.5;stroke-miterlimit:10;}
	.st158{fill:none;stroke:url(#SVGID_162_);stroke-width:0.5;stroke-miterlimit:10;}
	.st159{fill:none;stroke:url(#SVGID_163_);stroke-width:0.5;stroke-miterlimit:10;}
	.st160{fill:none;stroke:url(#SVGID_164_);stroke-width:0.5;stroke-miterlimit:10;}
	.st161{fill:none;stroke:url(#SVGID_165_);stroke-width:0.5;stroke-miterlimit:10;}
	.st162{fill:none;stroke:url(#SVGID_166_);stroke-width:0.5;stroke-miterlimit:10;}
	.st163{fill:none;stroke:url(#SVGID_167_);stroke-width:0.5;stroke-miterlimit:10;}
	.st164{fill:none;stroke:url(#SVGID_168_);stroke-width:0.5;stroke-miterlimit:10;}
	.st165{fill:none;stroke:url(#SVGID_169_);stroke-width:0.5;stroke-miterlimit:10;}
	.st166{fill:none;stroke:url(#SVGID_170_);stroke-width:0.5;stroke-miterlimit:10;}
	.st167{fill:none;stroke:url(#SVGID_171_);stroke-width:0.5;stroke-miterlimit:10;}
	.st168{fill:none;stroke:url(#SVGID_172_);stroke-width:0.5;stroke-miterlimit:10;}
	.st169{fill:none;stroke:url(#SVGID_173_);stroke-width:0.5;stroke-miterlimit:10;}
	.st170{fill:none;stroke:url(#SVGID_174_);stroke-width:0.5;stroke-miterlimit:10;}
	.st171{fill:none;stroke:url(#SVGID_175_);stroke-width:0.5;stroke-miterlimit:10;}
	.st172{fill:none;stroke:url(#SVGID_176_);stroke-width:0.5;stroke-miterlimit:10;}
	.st173{fill:none;stroke:url(#SVGID_177_);stroke-width:0.5;stroke-miterlimit:10;}
	.st174{fill:none;stroke:url(#SVGID_178_);stroke-width:0.5;stroke-miterlimit:10;}
	.st175{clip-path:url(#SVGID_180_);}
	.st176{fill:none;stroke:url(#SVGID_181_);stroke-width:2;stroke-miterlimit:10;}
	.st177{fill:none;stroke:url(#SVGID_182_);stroke-width:2;stroke-miterlimit:10;}
	.st178{fill:none;stroke:url(#SVGID_183_);stroke-width:2;stroke-miterlimit:10;}
	.st179{fill:none;stroke:url(#SVGID_184_);stroke-width:2;stroke-miterlimit:10;}
	.st180{fill:none;stroke:url(#SVGID_185_);stroke-width:2;stroke-miterlimit:10;}
	.st181{fill:none;stroke:url(#SVGID_186_);stroke-width:2;stroke-miterlimit:10;}
	.st182{fill:none;stroke:url(#SVGID_187_);stroke-width:2;stroke-miterlimit:10;}
	.st183{fill:none;stroke:url(#SVGID_188_);stroke-width:2;stroke-miterlimit:10;}
	.st184{fill:none;stroke:url(#SVGID_189_);stroke-width:2;stroke-miterlimit:10;}
	.st185{fill:none;stroke:url(#SVGID_190_);stroke-width:2;stroke-miterlimit:10;}
	.st186{fill:none;stroke:url(#SVGID_191_);stroke-width:2;stroke-miterlimit:10;}
	.st187{opacity:0.7;}
	.st188{opacity:0.12;clip-path:url(#SVGID_193_);}
	.st189{fill:none;stroke:#B2B2B2;stroke-width:2;stroke-miterlimit:10;}
	.st190{fill:url(#SVGID_194_);}
	.st191{fill:none;stroke:#1D1D1B;stroke-width:3;stroke-miterlimit:10;}
	.st192{fill:url(#SVGID_195_);}
	.st193{opacity:0.07;clip-path:url(#SVGID_197_);}
	.st194{opacity:0.4;clip-path:url(#SVGID_197_);fill:url(#SVGID_198_);}
	.st195{fill:none;stroke:url(#SVGID_199_);stroke-width:3;stroke-miterlimit:10;}
	.st196{fill:url(#SVGID_200_);}
	.st197{fill:url(#SVGID_201_);}
	.st198{fill:url(#SVGID_202_);}
	.st199{fill:url(#SVGID_203_);}
	.st200{clip-path:url(#SVGID_205_);}
	.st201{fill:none;stroke:url(#SVGID_206_);stroke-width:0.5;stroke-miterlimit:10;}
	.st202{fill:none;stroke:url(#SVGID_207_);stroke-width:0.5;stroke-miterlimit:10;}
	.st203{fill:none;stroke:url(#SVGID_208_);stroke-width:0.5;stroke-miterlimit:10;}
	.st204{fill:none;stroke:url(#SVGID_209_);stroke-width:0.5;stroke-miterlimit:10;}
	.st205{fill:none;stroke:url(#SVGID_210_);stroke-width:0.5;stroke-miterlimit:10;}
	.st206{fill:none;stroke:url(#SVGID_211_);stroke-width:0.5;stroke-miterlimit:10;}
	.st207{fill:none;stroke:url(#SVGID_212_);stroke-width:0.5;stroke-miterlimit:10;}
	.st208{fill:none;stroke:url(#SVGID_213_);stroke-width:0.5;stroke-miterlimit:10;}
	.st209{fill:none;stroke:url(#SVGID_214_);stroke-width:0.5;stroke-miterlimit:10;}
	.st210{fill:none;stroke:url(#SVGID_215_);stroke-width:0.5;stroke-miterlimit:10;}
	.st211{fill:none;stroke:url(#SVGID_216_);stroke-width:0.5;stroke-miterlimit:10;}
	.st212{fill:none;stroke:url(#SVGID_217_);stroke-width:0.5;stroke-miterlimit:10;}
	.st213{fill:none;stroke:url(#SVGID_218_);stroke-width:0.5;stroke-miterlimit:10;}
	.st214{fill:none;stroke:url(#SVGID_219_);stroke-width:0.5;stroke-miterlimit:10;}
	.st215{fill:none;stroke:url(#SVGID_220_);stroke-width:0.5;stroke-miterlimit:10;}
	.st216{fill:none;stroke:url(#SVGID_221_);stroke-width:0.5;stroke-miterlimit:10;}
	.st217{fill:none;stroke:url(#SVGID_222_);stroke-width:0.5;stroke-miterlimit:10;}
	.st218{fill:none;stroke:url(#SVGID_223_);stroke-width:0.5;stroke-miterlimit:10;}
	.st219{fill:none;stroke:url(#SVGID_224_);stroke-width:0.5;stroke-miterlimit:10;}
	.st220{fill:none;stroke:url(#SVGID_225_);stroke-width:0.5;stroke-miterlimit:10;}
	.st221{fill:none;stroke:url(#SVGID_226_);stroke-width:0.5;stroke-miterlimit:10;}
	.st222{fill:none;stroke:url(#SVGID_227_);stroke-width:0.5;stroke-miterlimit:10;}
	.st223{fill:none;stroke:url(#SVGID_228_);stroke-width:0.5;stroke-miterlimit:10;}
	.st224{fill:none;stroke:url(#SVGID_229_);stroke-width:0.5;stroke-miterlimit:10;}
	.st225{fill:none;stroke:url(#SVGID_230_);stroke-width:0.5;stroke-miterlimit:10;}
	.st226{fill:none;stroke:url(#SVGID_231_);stroke-width:0.5;stroke-miterlimit:10;}
	.st227{fill:none;stroke:url(#SVGID_232_);stroke-width:0.5;stroke-miterlimit:10;}
	.st228{fill:none;stroke:url(#SVGID_233_);stroke-width:0.5;stroke-miterlimit:10;}
	.st229{fill:none;stroke:url(#SVGID_234_);stroke-width:0.5;stroke-miterlimit:10;}
	.st230{fill:none;stroke:url(#SVGID_235_);stroke-width:0.5;stroke-miterlimit:10;}
	.st231{fill:none;stroke:url(#SVGID_236_);stroke-width:0.5;stroke-miterlimit:10;}
	.st232{fill:none;stroke:url(#SVGID_237_);stroke-width:0.5;stroke-miterlimit:10;}
	.st233{fill:none;stroke:url(#SVGID_238_);stroke-width:0.5;stroke-miterlimit:10;}
	.st234{fill:none;stroke:url(#SVGID_239_);stroke-width:0.5;stroke-miterlimit:10;}
	.st235{fill:none;stroke:url(#SVGID_240_);stroke-width:0.5;stroke-miterlimit:10;}
	.st236{fill:none;stroke:url(#SVGID_241_);stroke-width:0.5;stroke-miterlimit:10;}
	.st237{fill:none;stroke:url(#SVGID_242_);stroke-width:0.5;stroke-miterlimit:10;}
	.st238{fill:none;stroke:url(#SVGID_243_);stroke-width:0.5;stroke-miterlimit:10;}
	.st239{fill:none;stroke:url(#SVGID_244_);stroke-width:0.5;stroke-miterlimit:10;}
	.st240{fill:none;stroke:url(#SVGID_245_);stroke-width:0.5;stroke-miterlimit:10;}
	.st241{fill:none;stroke:url(#SVGID_246_);stroke-width:0.5;stroke-miterlimit:10;}
	.st242{fill:none;stroke:url(#SVGID_247_);stroke-width:0.5;stroke-miterlimit:10;}
	.st243{fill:none;stroke:url(#SVGID_248_);stroke-width:3;stroke-miterlimit:10;}
	.st244{fill:none;stroke:url(#SVGID_249_);stroke-width:3;stroke-miterlimit:10;}
	.st245{fill:none;stroke:url(#SVGID_250_);stroke-width:3;stroke-miterlimit:10;}
	.st246{fill:none;stroke:url(#SVGID_251_);stroke-width:3;stroke-miterlimit:10;}
	.st247{fill:none;stroke:url(#SVGID_252_);stroke-width:3;stroke-miterlimit:10;}
	.st248{fill:none;stroke:url(#SVGID_253_);stroke-width:3;stroke-miterlimit:10;}
	.st249{fill:none;stroke:url(#SVGID_254_);stroke-width:3;stroke-miterlimit:10;}
	.st250{fill:url(#SVGID_255_);}
	.st251{fill:url(#SVGID_256_);}
	.st252{fill:url(#SVGID_257_);}
	.st253{clip-path:url(#SVGID_259_);}
	.st254{fill:none;stroke:url(#SVGID_260_);stroke-width:0.5;stroke-miterlimit:10;}
	.st255{fill:none;stroke:url(#SVGID_261_);stroke-width:0.5;stroke-miterlimit:10;}
	.st256{fill:none;stroke:url(#SVGID_262_);stroke-width:0.5;stroke-miterlimit:10;}
	.st257{fill:none;stroke:url(#SVGID_263_);stroke-width:0.5;stroke-miterlimit:10;}
	.st258{fill:none;stroke:url(#SVGID_264_);stroke-width:0.5;stroke-miterlimit:10;}
	.st259{fill:none;stroke:url(#SVGID_265_);stroke-width:0.5;stroke-miterlimit:10;}
	.st260{fill:none;stroke:url(#SVGID_266_);stroke-width:0.5;stroke-miterlimit:10;}
	.st261{fill:none;stroke:url(#SVGID_267_);stroke-width:0.5;stroke-miterlimit:10;}
	.st262{fill:none;stroke:url(#SVGID_268_);stroke-width:0.5;stroke-miterlimit:10;}
	.st263{fill:none;stroke:url(#SVGID_269_);stroke-width:0.5;stroke-miterlimit:10;}
	.st264{fill:none;stroke:url(#SVGID_270_);stroke-width:0.5;stroke-miterlimit:10;}
	.st265{fill:none;stroke:url(#SVGID_271_);stroke-width:0.5;stroke-miterlimit:10;}
	.st266{fill:none;stroke:url(#SVGID_272_);stroke-width:0.5;stroke-miterlimit:10;}
	.st267{fill:none;stroke:url(#SVGID_273_);stroke-width:0.5;stroke-miterlimit:10;}
	.st268{fill:none;stroke:url(#SVGID_274_);stroke-width:0.5;stroke-miterlimit:10;}
	.st269{fill:none;stroke:url(#SVGID_275_);stroke-width:0.5;stroke-miterlimit:10;}
	.st270{fill:none;stroke:url(#SVGID_276_);stroke-width:0.5;stroke-miterlimit:10;}
	.st271{fill:none;stroke:url(#SVGID_277_);stroke-width:0.5;stroke-miterlimit:10;}
	.st272{fill:none;stroke:url(#SVGID_278_);stroke-width:0.5;stroke-miterlimit:10;}
	.st273{fill:none;stroke:url(#SVGID_279_);stroke-width:0.5;stroke-miterlimit:10;}
	.st274{fill:none;stroke:url(#SVGID_280_);stroke-width:0.5;stroke-miterlimit:10;}
	.st275{fill:none;stroke:url(#SVGID_281_);stroke-width:0.5;stroke-miterlimit:10;}
	.st276{fill:none;stroke:url(#SVGID_282_);stroke-width:0.5;stroke-miterlimit:10;}
	.st277{fill:none;stroke:url(#SVGID_283_);stroke-width:0.5;stroke-miterlimit:10;}
	.st278{fill:none;stroke:url(#SVGID_284_);stroke-width:0.5;stroke-miterlimit:10;}
	.st279{fill:none;stroke:url(#SVGID_285_);stroke-width:0.5;stroke-miterlimit:10;}
	.st280{fill:none;stroke:url(#SVGID_286_);stroke-width:0.5;stroke-miterlimit:10;}
	.st281{fill:none;stroke:url(#SVGID_287_);stroke-width:0.5;stroke-miterlimit:10;}
	.st282{fill:none;stroke:url(#SVGID_288_);stroke-width:0.5;stroke-miterlimit:10;}
	.st283{fill:none;stroke:url(#SVGID_289_);stroke-width:0.5;stroke-miterlimit:10;}
	.st284{fill:none;stroke:url(#SVGID_290_);stroke-width:0.5;stroke-miterlimit:10;}
	.st285{fill:none;stroke:url(#SVGID_291_);stroke-width:0.5;stroke-miterlimit:10;}
	.st286{fill:none;stroke:url(#SVGID_292_);stroke-width:0.5;stroke-miterlimit:10;}
	.st287{fill:none;stroke:url(#SVGID_293_);stroke-width:0.5;stroke-miterlimit:10;}
	.st288{fill:none;stroke:url(#SVGID_294_);stroke-width:0.5;stroke-miterlimit:10;}
	.st289{fill:none;stroke:url(#SVGID_295_);stroke-width:0.5;stroke-miterlimit:10;}
	.st290{fill:none;stroke:url(#SVGID_296_);stroke-width:0.5;stroke-miterlimit:10;}
	.st291{fill:none;stroke:url(#SVGID_297_);stroke-width:0.5;stroke-miterlimit:10;}
	.st292{fill:none;stroke:url(#SVGID_298_);stroke-width:0.5;stroke-miterlimit:10;}
	.st293{fill:none;stroke:url(#SVGID_299_);stroke-width:0.5;stroke-miterlimit:10;}
	.st294{fill:none;stroke:url(#SVGID_300_);stroke-width:0.5;stroke-miterlimit:10;}
	.st295{fill:none;stroke:url(#SVGID_301_);stroke-width:0.5;stroke-miterlimit:10;}
	.st296{fill:none;stroke:url(#SVGID_302_);stroke-width:3;stroke-miterlimit:10;}
	.st297{fill:none;stroke:url(#SVGID_303_);stroke-width:3;stroke-miterlimit:10;}
	.st298{fill:none;stroke:url(#SVGID_304_);stroke-width:3;stroke-miterlimit:10;}
	.st299{fill:none;stroke:url(#SVGID_305_);stroke-width:3;stroke-miterlimit:10;}
	.st300{fill:none;stroke:url(#SVGID_306_);stroke-width:3;stroke-miterlimit:10;}
	.st301{fill:none;stroke:url(#SVGID_307_);stroke-width:3;stroke-miterlimit:10;}
	.st302{fill:none;stroke:url(#SVGID_308_);stroke-width:3;stroke-miterlimit:10;}
	.st303{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
	.st304{fill:url(#SVGID_309_);}
	.st305{fill:none;stroke:url(#SVGID_310_);stroke-width:3;stroke-miterlimit:10;}
	.st306{fill:none;stroke:#B2B2B2;stroke-width:3;stroke-miterlimit:10;}
	.st307{clip-path:url(#SVGID_312_);}
	.st308{opacity:0.51;fill:none;stroke:url(#SVGID_313_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st309{opacity:0.51;fill:none;stroke:url(#SVGID_314_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st310{opacity:0.82;fill:none;stroke:url(#SVGID_315_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st311{opacity:0.51;fill:none;stroke:url(#SVGID_316_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st312{opacity:0.51;fill:none;stroke:url(#SVGID_317_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st313{opacity:0.51;fill:none;stroke:url(#SVGID_318_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st314{opacity:0.82;fill:none;stroke:url(#SVGID_319_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st315{opacity:0.51;fill:none;stroke:url(#SVGID_320_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st316{fill:url(#SVGID_321_);}
	.st317{fill:none;stroke:url(#SVGID_322_);stroke-width:3;stroke-miterlimit:10;}
	.st318{clip-path:url(#SVGID_324_);}
	.st319{opacity:0.51;fill:none;stroke:url(#SVGID_325_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st320{opacity:0.51;fill:none;stroke:url(#SVGID_326_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st321{opacity:0.82;fill:none;stroke:url(#SVGID_327_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st322{opacity:0.51;fill:none;stroke:url(#SVGID_328_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st323{opacity:0.51;fill:none;stroke:url(#SVGID_329_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st324{opacity:0.51;fill:none;stroke:url(#SVGID_330_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st325{opacity:0.82;fill:none;stroke:url(#SVGID_331_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st326{opacity:0.51;fill:none;stroke:url(#SVGID_332_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st327{fill:url(#SVGID_333_);}
	.st328{fill:none;stroke:url(#SVGID_334_);stroke-width:2;stroke-miterlimit:10;}
	.st329{fill:url(#SVGID_335_);}
	.st330{opacity:0.25;}
	.st331{fill:url(#SVGID_336_);}
	.st332{fill:url(#SVGID_337_);}
	.st333{fill:url(#SVGID_338_);}
	.st334{fill:url(#SVGID_339_);}
	.st335{fill:url(#SVGID_340_);}
	.st336{fill:url(#SVGID_341_);}
	.st337{fill:url(#SVGID_342_);}
	.st338{fill:url(#SVGID_343_);}
	.st339{fill:url(#SVGID_344_);}
	.st340{fill:url(#SVGID_345_);}
	.st341{fill:url(#SVGID_346_);}
	.st342{fill:url(#SVGID_347_);}
	.st343{fill:url(#SVGID_348_);}
	.st344{fill:url(#SVGID_349_);}
	.st345{fill:url(#SVGID_350_);}
	.st346{fill:url(#SVGID_351_);}
	.st347{fill:url(#SVGID_352_);}
	.st348{fill:url(#SVGID_353_);}
	.st349{fill:url(#SVGID_354_);}
	.st350{fill:url(#SVGID_355_);}
	.st351{fill:url(#SVGID_356_);}
	.st352{fill:url(#SVGID_357_);}
	.st353{fill:url(#SVGID_358_);}
	.st354{fill:url(#SVGID_359_);}
	.st355{fill:url(#SVGID_360_);}
	.st356{fill:url(#SVGID_361_);}
	.st357{fill:url(#SVGID_362_);}
	.st358{fill:url(#SVGID_363_);}
	.st359{fill:url(#SVGID_364_);}
	.st360{fill:url(#SVGID_365_);}
	.st361{fill:url(#SVGID_366_);}
	.st362{fill:url(#SVGID_367_);}
	.st363{fill:url(#SVGID_368_);}
	.st364{fill:url(#SVGID_369_);}
	.st365{fill:url(#SVGID_370_);}
	.st366{fill:url(#SVGID_371_);}
	.st367{fill:url(#SVGID_372_);}
	.st368{fill:url(#SVGID_373_);}
	.st369{fill:url(#SVGID_374_);}
	.st370{fill:url(#SVGID_375_);}
	.st371{fill:#071D50;stroke:url(#SVGID_376_);stroke-width:2;stroke-miterlimit:10;}
	.st372{opacity:0.12;clip-path:url(#SVGID_378_);}
	.st373{fill:#071D50;}
	.st374{fill:url(#SVGID_379_);}
	.st375{fill:none;stroke:url(#SVGID_380_);stroke-width:3;stroke-miterlimit:10;}
	.st376{fill:url(#SVGID_381_);}
	.st377{fill:url(#SVGID_382_);}
	.st378{fill:url(#SVGID_383_);}
	.st379{fill:url(#SVGID_384_);}
	.st380{fill:url(#SVGID_385_);}
	.st381{fill:url(#SVGID_386_);}
	.st382{fill:url(#SVGID_387_);}
	.st383{fill:url(#SVGID_388_);}
	.st384{fill:url(#SVGID_389_);}
	.st385{fill:url(#SVGID_390_);}
	.st386{fill:url(#SVGID_391_);}
	.st387{fill:url(#SVGID_392_);}
	.st388{fill:url(#SVGID_393_);}
	.st389{fill:url(#SVGID_394_);}
	.st390{fill:url(#SVGID_395_);}
	.st391{fill:url(#SVGID_396_);}
	.st392{fill:url(#SVGID_397_);}
	.st393{fill:url(#SVGID_398_);}
	.st394{fill:url(#SVGID_399_);}
	.st395{opacity:0.6;clip-path:url(#SVGID_401_);fill:url(#SVGID_402_);}
	.st396{fill:none;stroke:url(#SVGID_403_);stroke-width:3;stroke-miterlimit:10;}
	.st397{fill:url(#SVGID_404_);}
	.st398{opacity:0.07;clip-path:url(#SVGID_406_);}
	.st399{opacity:0.4;clip-path:url(#SVGID_406_);fill:url(#SVGID_407_);}
	.st400{fill:none;stroke:url(#SVGID_408_);stroke-width:3;stroke-miterlimit:10;}
	.st401{fill:url(#SVGID_409_);}
	.st402{fill:url(#SVGID_410_);}
	.st403{fill:url(#SVGID_411_);}
	.st404{clip-path:url(#SVGID_413_);}
	.st405{fill:none;stroke:url(#SVGID_414_);stroke-width:0.5;stroke-miterlimit:10;}
	.st406{fill:none;stroke:url(#SVGID_415_);stroke-width:0.5;stroke-miterlimit:10;}
	.st407{fill:none;stroke:url(#SVGID_416_);stroke-width:0.5;stroke-miterlimit:10;}
	.st408{fill:none;stroke:url(#SVGID_417_);stroke-width:0.5;stroke-miterlimit:10;}
	.st409{fill:none;stroke:url(#SVGID_418_);stroke-width:0.5;stroke-miterlimit:10;}
	.st410{fill:none;stroke:url(#SVGID_419_);stroke-width:0.5;stroke-miterlimit:10;}
	.st411{fill:none;stroke:url(#SVGID_420_);stroke-width:0.5;stroke-miterlimit:10;}
	.st412{fill:none;stroke:url(#SVGID_421_);stroke-width:0.5;stroke-miterlimit:10;}
	.st413{fill:none;stroke:url(#SVGID_422_);stroke-width:0.5;stroke-miterlimit:10;}
	.st414{fill:none;stroke:url(#SVGID_423_);stroke-width:0.5;stroke-miterlimit:10;}
	.st415{fill:none;stroke:url(#SVGID_424_);stroke-width:0.5;stroke-miterlimit:10;}
	.st416{fill:none;stroke:url(#SVGID_425_);stroke-width:0.5;stroke-miterlimit:10;}
	.st417{fill:none;stroke:url(#SVGID_426_);stroke-width:0.5;stroke-miterlimit:10;}
	.st418{fill:none;stroke:url(#SVGID_427_);stroke-width:0.5;stroke-miterlimit:10;}
	.st419{fill:none;stroke:url(#SVGID_428_);stroke-width:0.5;stroke-miterlimit:10;}
	.st420{fill:none;stroke:url(#SVGID_429_);stroke-width:0.5;stroke-miterlimit:10;}
	.st421{fill:none;stroke:url(#SVGID_430_);stroke-width:0.5;stroke-miterlimit:10;}
	.st422{fill:none;stroke:url(#SVGID_431_);stroke-width:0.5;stroke-miterlimit:10;}
	.st423{fill:none;stroke:url(#SVGID_432_);stroke-width:0.5;stroke-miterlimit:10;}
	.st424{fill:none;stroke:url(#SVGID_433_);stroke-width:0.5;stroke-miterlimit:10;}
	.st425{fill:none;stroke:url(#SVGID_434_);stroke-width:0.5;stroke-miterlimit:10;}
	.st426{fill:none;stroke:url(#SVGID_435_);stroke-width:0.5;stroke-miterlimit:10;}
	.st427{fill:none;stroke:url(#SVGID_436_);stroke-width:0.5;stroke-miterlimit:10;}
	.st428{fill:none;stroke:url(#SVGID_437_);stroke-width:0.5;stroke-miterlimit:10;}
	.st429{fill:none;stroke:url(#SVGID_438_);stroke-width:0.5;stroke-miterlimit:10;}
	.st430{fill:none;stroke:url(#SVGID_439_);stroke-width:0.5;stroke-miterlimit:10;}
	.st431{fill:none;stroke:url(#SVGID_440_);stroke-width:0.5;stroke-miterlimit:10;}
	.st432{fill:none;stroke:url(#SVGID_441_);stroke-width:0.5;stroke-miterlimit:10;}
	.st433{fill:none;stroke:url(#SVGID_442_);stroke-width:0.5;stroke-miterlimit:10;}
	.st434{fill:none;stroke:url(#SVGID_443_);stroke-width:0.5;stroke-miterlimit:10;}
	.st435{fill:none;stroke:url(#SVGID_444_);stroke-width:0.5;stroke-miterlimit:10;}
	.st436{fill:none;stroke:url(#SVGID_445_);stroke-width:0.5;stroke-miterlimit:10;}
	.st437{fill:none;stroke:url(#SVGID_446_);stroke-width:0.5;stroke-miterlimit:10;}
	.st438{fill:none;stroke:url(#SVGID_447_);stroke-width:0.5;stroke-miterlimit:10;}
	.st439{fill:none;stroke:url(#SVGID_448_);stroke-width:0.5;stroke-miterlimit:10;}
	.st440{fill:none;stroke:url(#SVGID_449_);stroke-width:0.5;stroke-miterlimit:10;}
	.st441{fill:none;stroke:url(#SVGID_450_);stroke-width:0.5;stroke-miterlimit:10;}
	.st442{fill:none;stroke:url(#SVGID_451_);stroke-width:0.5;stroke-miterlimit:10;}
	.st443{fill:none;stroke:url(#SVGID_452_);stroke-width:0.5;stroke-miterlimit:10;}
	.st444{fill:none;stroke:url(#SVGID_453_);stroke-width:0.5;stroke-miterlimit:10;}
	.st445{fill:none;stroke:url(#SVGID_454_);stroke-width:0.5;stroke-miterlimit:10;}
	.st446{fill:none;stroke:url(#SVGID_455_);stroke-width:0.5;stroke-miterlimit:10;}
	.st447{clip-path:url(#SVGID_457_);}
	.st448{fill:none;stroke:url(#SVGID_458_);stroke-width:2;stroke-miterlimit:10;}
	.st449{fill:none;stroke:url(#SVGID_459_);stroke-width:2;stroke-miterlimit:10;}
	.st450{fill:none;stroke:url(#SVGID_460_);stroke-width:2;stroke-miterlimit:10;}
	.st451{fill:none;stroke:url(#SVGID_461_);stroke-width:2;stroke-miterlimit:10;}
	.st452{fill:none;stroke:url(#SVGID_462_);stroke-width:2;stroke-miterlimit:10;}
	.st453{fill:none;stroke:url(#SVGID_463_);stroke-width:2;stroke-miterlimit:10;}
	.st454{fill:none;stroke:url(#SVGID_464_);stroke-width:2;stroke-miterlimit:10;}
	.st455{fill:none;stroke:url(#SVGID_465_);stroke-width:2;stroke-miterlimit:10;}
	.st456{fill:none;stroke:url(#SVGID_466_);stroke-width:2;stroke-miterlimit:10;}
	.st457{fill:none;stroke:url(#SVGID_467_);stroke-width:2;stroke-miterlimit:10;}
	.st458{fill:url(#SVGID_468_);}
	.st459{fill:url(#SVGID_469_);}
	.st460{fill:url(#SVGID_470_);}
	.st461{clip-path:url(#SVGID_472_);}
	.st462{fill:url(#SVGID_473_);}
	.st463{fill:url(#SVGID_474_);}
	.st464{fill:url(#SVGID_475_);}
	.st465{fill:url(#SVGID_476_);}
	.st466{fill:url(#SVGID_477_);}
	.st467{fill:url(#SVGID_478_);}
	.st468{fill:url(#SVGID_479_);}
	.st469{fill:url(#SVGID_480_);}
	.st470{fill:url(#SVGID_481_);}
	.st471{fill:url(#SVGID_482_);}
	.st472{fill:url(#SVGID_483_);}
	.st473{fill:url(#SVGID_484_);}
	.st474{fill:url(#SVGID_485_);}
	.st475{fill:url(#SVGID_486_);}
	.st476{fill:url(#SVGID_487_);}
	.st477{fill:url(#SVGID_488_);}
	.st478{fill:url(#SVGID_489_);}
	.st479{fill:url(#SVGID_490_);}
	.st480{fill:url(#SVGID_491_);}
	.st481{fill:url(#SVGID_492_);}
	.st482{fill:url(#SVGID_493_);}
	.st483{fill:url(#SVGID_494_);}
	.st484{fill:url(#SVGID_495_);}
	.st485{fill:url(#SVGID_496_);}
	.st486{fill:url(#SVGID_497_);}
	.st487{fill:url(#SVGID_498_);}
	.st488{fill:url(#SVGID_499_);}
	.st489{fill:url(#SVGID_500_);}
	.st490{fill:url(#SVGID_501_);}
	.st491{fill:url(#SVGID_502_);}
	.st492{fill:url(#SVGID_503_);}
	.st493{fill:url(#SVGID_504_);}
	.st494{fill:url(#SVGID_505_);}
	.st495{fill:url(#SVGID_506_);}
	.st496{fill:url(#SVGID_507_);}
	.st497{fill:url(#SVGID_508_);}
	.st498{fill:url(#SVGID_509_);}
	.st499{fill:url(#SVGID_510_);}
	.st500{fill:url(#SVGID_511_);}
	.st501{fill:url(#SVGID_512_);}
	.st502{fill:url(#SVGID_513_);}
	.st503{fill:url(#SVGID_514_);}
	.st504{clip-path:url(#SVGID_516_);}
	.st505{fill:url(#SVGID_517_);}
	.st506{fill:url(#SVGID_518_);}
	.st507{fill:url(#SVGID_519_);}
	.st508{fill:url(#SVGID_520_);}
	.st509{fill:url(#SVGID_521_);}
	.st510{fill:url(#SVGID_522_);}
	.st511{fill:url(#SVGID_523_);}
	.st512{fill:url(#SVGID_524_);}
	.st513{fill:url(#SVGID_525_);}
	.st514{fill:url(#SVGID_526_);}
	.st515{fill:url(#SVGID_527_);}
	.st516{opacity:0.12;clip-path:url(#SVGID_529_);}
	.st517{fill:#B2B2B2;}
	.st518{fill:url(#SVGID_530_);}
	.st519{fill:url(#SVGID_531_);}
	.st520{fill:url(#SVGID_532_);}
	.st521{fill:url(#SVGID_533_);}
	.st522{clip-path:url(#SVGID_535_);}
	.st523{fill:none;stroke:url(#SVGID_536_);stroke-width:0.5;stroke-miterlimit:10;}
	.st524{fill:none;stroke:url(#SVGID_537_);stroke-width:0.5;stroke-miterlimit:10;}
	.st525{fill:none;stroke:url(#SVGID_538_);stroke-width:0.5;stroke-miterlimit:10;}
	.st526{fill:none;stroke:url(#SVGID_539_);stroke-width:0.5;stroke-miterlimit:10;}
	.st527{fill:none;stroke:url(#SVGID_540_);stroke-width:0.5;stroke-miterlimit:10;}
	.st528{fill:none;stroke:url(#SVGID_541_);stroke-width:0.5;stroke-miterlimit:10;}
	.st529{fill:none;stroke:url(#SVGID_542_);stroke-width:0.5;stroke-miterlimit:10;}
	.st530{fill:none;stroke:url(#SVGID_543_);stroke-width:0.5;stroke-miterlimit:10;}
	.st531{fill:none;stroke:url(#SVGID_544_);stroke-width:0.5;stroke-miterlimit:10;}
	.st532{fill:none;stroke:url(#SVGID_545_);stroke-width:0.5;stroke-miterlimit:10;}
	.st533{fill:none;stroke:url(#SVGID_546_);stroke-width:0.5;stroke-miterlimit:10;}
	.st534{fill:none;stroke:url(#SVGID_547_);stroke-width:0.5;stroke-miterlimit:10;}
	.st535{fill:none;stroke:url(#SVGID_548_);stroke-width:0.5;stroke-miterlimit:10;}
	.st536{fill:none;stroke:url(#SVGID_549_);stroke-width:0.5;stroke-miterlimit:10;}
	.st537{fill:none;stroke:url(#SVGID_550_);stroke-width:0.5;stroke-miterlimit:10;}
	.st538{fill:none;stroke:url(#SVGID_551_);stroke-width:0.5;stroke-miterlimit:10;}
	.st539{fill:none;stroke:url(#SVGID_552_);stroke-width:0.5;stroke-miterlimit:10;}
	.st540{fill:none;stroke:url(#SVGID_553_);stroke-width:0.5;stroke-miterlimit:10;}
	.st541{fill:none;stroke:url(#SVGID_554_);stroke-width:0.5;stroke-miterlimit:10;}
	.st542{fill:none;stroke:url(#SVGID_555_);stroke-width:0.5;stroke-miterlimit:10;}
	.st543{fill:none;stroke:url(#SVGID_556_);stroke-width:0.5;stroke-miterlimit:10;}
	.st544{fill:none;stroke:url(#SVGID_557_);stroke-width:0.5;stroke-miterlimit:10;}
	.st545{fill:none;stroke:url(#SVGID_558_);stroke-width:0.5;stroke-miterlimit:10;}
	.st546{fill:none;stroke:url(#SVGID_559_);stroke-width:0.5;stroke-miterlimit:10;}
	.st547{fill:none;stroke:url(#SVGID_560_);stroke-width:0.5;stroke-miterlimit:10;}
	.st548{fill:none;stroke:url(#SVGID_561_);stroke-width:0.5;stroke-miterlimit:10;}
	.st549{fill:none;stroke:url(#SVGID_562_);stroke-width:0.5;stroke-miterlimit:10;}
	.st550{fill:none;stroke:url(#SVGID_563_);stroke-width:0.5;stroke-miterlimit:10;}
	.st551{fill:none;stroke:url(#SVGID_564_);stroke-width:0.5;stroke-miterlimit:10;}
	.st552{fill:none;stroke:url(#SVGID_565_);stroke-width:0.5;stroke-miterlimit:10;}
	.st553{fill:none;stroke:url(#SVGID_566_);stroke-width:0.5;stroke-miterlimit:10;}
	.st554{fill:none;stroke:url(#SVGID_567_);stroke-width:0.5;stroke-miterlimit:10;}
	.st555{fill:none;stroke:url(#SVGID_568_);stroke-width:0.5;stroke-miterlimit:10;}
	.st556{fill:none;stroke:url(#SVGID_569_);stroke-width:0.5;stroke-miterlimit:10;}
	.st557{fill:none;stroke:url(#SVGID_570_);stroke-width:0.5;stroke-miterlimit:10;}
	.st558{fill:none;stroke:url(#SVGID_571_);stroke-width:0.5;stroke-miterlimit:10;}
	.st559{fill:none;stroke:url(#SVGID_572_);stroke-width:0.5;stroke-miterlimit:10;}
	.st560{fill:none;stroke:url(#SVGID_573_);stroke-width:0.5;stroke-miterlimit:10;}
	.st561{fill:none;stroke:url(#SVGID_574_);stroke-width:0.5;stroke-miterlimit:10;}
	.st562{fill:none;stroke:url(#SVGID_575_);stroke-width:0.5;stroke-miterlimit:10;}
	.st563{fill:none;stroke:url(#SVGID_576_);stroke-width:0.5;stroke-miterlimit:10;}
	.st564{fill:none;stroke:url(#SVGID_577_);stroke-width:0.5;stroke-miterlimit:10;}
	.st565{fill:url(#SVGID_578_);}
	.st566{fill:url(#SVGID_579_);}
	.st567{fill:url(#SVGID_580_);}
	.st568{fill:url(#SVGID_581_);}
	.st569{fill:url(#SVGID_582_);}
	.st570{fill:url(#SVGID_583_);}
	.st571{fill:url(#SVGID_584_);}
	.st572{fill:url(#SVGID_585_);}
	.st573{fill:url(#SVGID_586_);}
	.st574{fill:url(#SVGID_587_);}
	.st575{clip-path:url(#SVGID_589_);}
	.st576{fill:none;stroke:url(#SVGID_590_);stroke-width:0.5;stroke-miterlimit:10;}
	.st577{fill:none;stroke:url(#SVGID_591_);stroke-width:0.5;stroke-miterlimit:10;}
	.st578{fill:none;stroke:url(#SVGID_592_);stroke-width:0.5;stroke-miterlimit:10;}
	.st579{fill:none;stroke:url(#SVGID_593_);stroke-width:0.5;stroke-miterlimit:10;}
	.st580{fill:none;stroke:url(#SVGID_594_);stroke-width:0.5;stroke-miterlimit:10;}
	.st581{fill:none;stroke:url(#SVGID_595_);stroke-width:0.5;stroke-miterlimit:10;}
	.st582{fill:none;stroke:url(#SVGID_596_);stroke-width:0.5;stroke-miterlimit:10;}
	.st583{fill:none;stroke:url(#SVGID_597_);stroke-width:0.5;stroke-miterlimit:10;}
	.st584{fill:none;stroke:url(#SVGID_598_);stroke-width:0.5;stroke-miterlimit:10;}
	.st585{fill:none;stroke:url(#SVGID_599_);stroke-width:0.5;stroke-miterlimit:10;}
	.st586{fill:none;stroke:url(#SVGID_600_);stroke-width:0.5;stroke-miterlimit:10;}
	.st587{fill:none;stroke:url(#SVGID_601_);stroke-width:0.5;stroke-miterlimit:10;}
	.st588{fill:none;stroke:url(#SVGID_602_);stroke-width:0.5;stroke-miterlimit:10;}
	.st589{fill:none;stroke:url(#SVGID_603_);stroke-width:0.5;stroke-miterlimit:10;}
	.st590{fill:none;stroke:url(#SVGID_604_);stroke-width:0.5;stroke-miterlimit:10;}
	.st591{fill:none;stroke:url(#SVGID_605_);stroke-width:0.5;stroke-miterlimit:10;}
	.st592{fill:none;stroke:url(#SVGID_606_);stroke-width:0.5;stroke-miterlimit:10;}
	.st593{fill:none;stroke:url(#SVGID_607_);stroke-width:0.5;stroke-miterlimit:10;}
	.st594{fill:none;stroke:url(#SVGID_608_);stroke-width:0.5;stroke-miterlimit:10;}
	.st595{fill:none;stroke:url(#SVGID_609_);stroke-width:0.5;stroke-miterlimit:10;}
	.st596{fill:none;stroke:url(#SVGID_610_);stroke-width:0.5;stroke-miterlimit:10;}
	.st597{fill:none;stroke:url(#SVGID_611_);stroke-width:0.5;stroke-miterlimit:10;}
	.st598{fill:none;stroke:url(#SVGID_612_);stroke-width:0.5;stroke-miterlimit:10;}
	.st599{fill:none;stroke:url(#SVGID_613_);stroke-width:0.5;stroke-miterlimit:10;}
	.st600{fill:none;stroke:url(#SVGID_614_);stroke-width:0.5;stroke-miterlimit:10;}
	.st601{fill:none;stroke:url(#SVGID_615_);stroke-width:0.5;stroke-miterlimit:10;}
	.st602{fill:none;stroke:url(#SVGID_616_);stroke-width:0.5;stroke-miterlimit:10;}
	.st603{fill:none;stroke:url(#SVGID_617_);stroke-width:0.5;stroke-miterlimit:10;}
	.st604{fill:none;stroke:url(#SVGID_618_);stroke-width:0.5;stroke-miterlimit:10;}
	.st605{fill:none;stroke:url(#SVGID_619_);stroke-width:0.5;stroke-miterlimit:10;}
	.st606{fill:none;stroke:url(#SVGID_620_);stroke-width:0.5;stroke-miterlimit:10;}
	.st607{fill:none;stroke:url(#SVGID_621_);stroke-width:0.5;stroke-miterlimit:10;}
	.st608{fill:none;stroke:url(#SVGID_622_);stroke-width:0.5;stroke-miterlimit:10;}
	.st609{fill:none;stroke:url(#SVGID_623_);stroke-width:0.5;stroke-miterlimit:10;}
	.st610{fill:none;stroke:url(#SVGID_624_);stroke-width:0.5;stroke-miterlimit:10;}
	.st611{fill:none;stroke:url(#SVGID_625_);stroke-width:0.5;stroke-miterlimit:10;}
	.st612{fill:none;stroke:url(#SVGID_626_);stroke-width:0.5;stroke-miterlimit:10;}
	.st613{fill:none;stroke:url(#SVGID_627_);stroke-width:0.5;stroke-miterlimit:10;}
	.st614{fill:none;stroke:url(#SVGID_628_);stroke-width:0.5;stroke-miterlimit:10;}
	.st615{fill:none;stroke:url(#SVGID_629_);stroke-width:0.5;stroke-miterlimit:10;}
	.st616{fill:none;stroke:url(#SVGID_630_);stroke-width:0.5;stroke-miterlimit:10;}
	.st617{fill:none;stroke:url(#SVGID_631_);stroke-width:0.5;stroke-miterlimit:10;}
	.st618{fill:none;stroke:url(#SVGID_632_);stroke-width:3;stroke-miterlimit:10;}
	.st619{fill:none;stroke:url(#SVGID_633_);stroke-width:3;stroke-miterlimit:10;}
	.st620{fill:none;stroke:url(#SVGID_634_);stroke-width:3;stroke-miterlimit:10;}
	.st621{fill:none;stroke:url(#SVGID_635_);stroke-width:3;stroke-miterlimit:10;}
	.st622{fill:none;stroke:url(#SVGID_636_);stroke-width:3;stroke-miterlimit:10;}
	.st623{fill:none;stroke:url(#SVGID_637_);stroke-width:3;stroke-miterlimit:10;}
	.st624{fill:none;stroke:url(#SVGID_638_);stroke-width:3;stroke-miterlimit:10;}
	.st625{fill:url(#SVGID_639_);}
	.st626{fill:url(#SVGID_640_);}
	.st627{clip-path:url(#SVGID_642_);}
	.st628{opacity:0.51;fill:none;stroke:url(#SVGID_643_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st629{opacity:0.51;fill:none;stroke:url(#SVGID_644_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st630{opacity:0.82;fill:none;stroke:url(#SVGID_645_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st631{opacity:0.51;fill:none;stroke:url(#SVGID_646_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st632{opacity:0.51;fill:none;stroke:url(#SVGID_647_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st633{opacity:0.51;fill:none;stroke:url(#SVGID_648_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st634{opacity:0.82;fill:none;stroke:url(#SVGID_649_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st635{opacity:0.51;fill:none;stroke:url(#SVGID_650_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st636{fill:url(#SVGID_651_);}
	.st637{fill:url(#SVGID_652_);}
	.st638{clip-path:url(#SVGID_654_);}
	.st639{opacity:0.51;fill:none;stroke:url(#SVGID_655_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st640{opacity:0.51;fill:none;stroke:url(#SVGID_656_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st641{opacity:0.82;fill:none;stroke:url(#SVGID_657_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st642{opacity:0.51;fill:none;stroke:url(#SVGID_658_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st643{opacity:0.51;fill:none;stroke:url(#SVGID_659_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st644{opacity:0.51;fill:none;stroke:url(#SVGID_660_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st645{opacity:0.82;fill:none;stroke:url(#SVGID_661_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st646{opacity:0.51;fill:none;stroke:url(#SVGID_662_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st647{fill:url(#SVGID_663_);}
	.st648{fill:none;stroke:url(#SVGID_664_);stroke-width:2;stroke-miterlimit:10;}
	.st649{fill:url(#SVGID_665_);}
	.st650{fill:url(#SVGID_666_);}
	.st651{fill:url(#SVGID_667_);}
	.st652{fill:url(#SVGID_668_);}
	.st653{fill:url(#SVGID_669_);}
	.st654{fill:url(#SVGID_670_);}
	.st655{fill:url(#SVGID_671_);}
	.st656{fill:url(#SVGID_672_);}
	.st657{fill:url(#SVGID_673_);}
	.st658{fill:url(#SVGID_674_);}
	.st659{fill:url(#SVGID_675_);}
	.st660{fill:url(#SVGID_676_);}
	.st661{fill:url(#SVGID_677_);}
	.st662{fill:url(#SVGID_678_);}
	.st663{fill:url(#SVGID_679_);}
	.st664{fill:url(#SVGID_680_);}
	.st665{fill:url(#SVGID_681_);}
	.st666{fill:url(#SVGID_682_);}
	.st667{fill:url(#SVGID_683_);}
	.st668{fill:url(#SVGID_684_);}
	.st669{fill:url(#SVGID_685_);}
	.st670{fill:url(#SVGID_686_);}
	.st671{fill:url(#SVGID_687_);}
	.st672{fill:url(#SVGID_688_);}
	.st673{fill:url(#SVGID_689_);}
	.st674{fill:url(#SVGID_690_);}
	.st675{fill:url(#SVGID_691_);}
	.st676{fill:url(#SVGID_692_);}
	.st677{fill:url(#SVGID_693_);}
	.st678{fill:url(#SVGID_694_);}
	.st679{fill:url(#SVGID_695_);}
	.st680{fill:url(#SVGID_696_);}
	.st681{fill:url(#SVGID_697_);}
	.st682{fill:url(#SVGID_698_);}
	.st683{fill:url(#SVGID_699_);}
	.st684{fill:url(#SVGID_700_);}
	.st685{fill:url(#SVGID_701_);}
	.st686{fill:url(#SVGID_702_);}
	.st687{fill:url(#SVGID_703_);}
	.st688{fill:url(#SVGID_704_);}
	.st689{fill:none;stroke:url(#SVGID_705_);stroke-width:3;stroke-miterlimit:10;}
	.st690{fill:url(#SVGID_706_);}
	.st691{fill:url(#SVGID_707_);}
	.st692{fill:url(#SVGID_708_);}
	.st693{fill:url(#SVGID_709_);}
	.st694{fill:url(#SVGID_710_);}
	.st695{fill:url(#SVGID_711_);}
	.st696{fill:url(#SVGID_712_);}
	.st697{fill:url(#SVGID_713_);}
	.st698{fill:url(#SVGID_714_);}
	.st699{fill:url(#SVGID_715_);}
	.st700{fill:url(#SVGID_716_);}
	.st701{fill:url(#SVGID_717_);}
	.st702{fill:url(#SVGID_718_);}
	.st703{fill:url(#SVGID_719_);}
	.st704{fill:url(#SVGID_720_);}
	.st705{fill:url(#SVGID_721_);}
	.st706{fill:url(#SVGID_722_);}
	.st707{fill:url(#SVGID_723_);}
	.st708{fill:none;stroke:url(#SVGID_724_);stroke-width:3;stroke-miterlimit:10;}
	.st709{fill:url(#SVGID_725_);}
	.st710{fill:url(#SVGID_726_);}
	.st711{fill:url(#SVGID_727_);}
	.st712{fill:url(#SVGID_728_);}
	.st713{fill:url(#SVGID_729_);}
	.st714{fill:url(#SVGID_730_);}
	.st715{fill:url(#SVGID_731_);}
	.st716{fill:url(#SVGID_732_);}
	.st717{fill:url(#SVGID_733_);}
	.st718{fill:url(#SVGID_734_);}
	.st719{fill:url(#SVGID_735_);}
	.st720{fill:url(#SVGID_736_);}
	.st721{fill:url(#SVGID_737_);}
	.st722{fill:url(#SVGID_738_);}
	.st723{fill:url(#SVGID_739_);}
	.st724{fill:url(#SVGID_740_);}
	.st725{fill:url(#SVGID_741_);}
	.st726{fill:url(#SVGID_742_);}
	.st727{fill:url(#SVGID_743_);}
	.st728{fill:url(#SVGID_744_);}
	.st729{opacity:0.6;clip-path:url(#SVGID_746_);fill:url(#SVGID_747_);}
	.st730{fill:none;stroke:url(#SVGID_748_);stroke-width:3;stroke-miterlimit:10;}
	.st731{fill:url(#SVGID_749_);}
	.st732{opacity:0.07;clip-path:url(#SVGID_751_);}
	.st733{opacity:0.4;clip-path:url(#SVGID_751_);fill:url(#SVGID_752_);}
	.st734{fill:url(#SVGID_753_);}
	.st735{fill:url(#SVGID_754_);}
	.st736{fill:url(#SVGID_755_);}
	.st737{fill:url(#SVGID_756_);}
	.st738{clip-path:url(#SVGID_758_);}
	.st739{fill:none;stroke:url(#SVGID_759_);stroke-width:0.5;stroke-miterlimit:10;}
	.st740{fill:none;stroke:url(#SVGID_760_);stroke-width:0.5;stroke-miterlimit:10;}
	.st741{fill:none;stroke:url(#SVGID_761_);stroke-width:0.5;stroke-miterlimit:10;}
	.st742{fill:none;stroke:url(#SVGID_762_);stroke-width:0.5;stroke-miterlimit:10;}
	.st743{fill:none;stroke:url(#SVGID_763_);stroke-width:0.5;stroke-miterlimit:10;}
	.st744{fill:none;stroke:url(#SVGID_764_);stroke-width:0.5;stroke-miterlimit:10;}
	.st745{fill:none;stroke:url(#SVGID_765_);stroke-width:0.5;stroke-miterlimit:10;}
	.st746{fill:none;stroke:url(#SVGID_766_);stroke-width:0.5;stroke-miterlimit:10;}
	.st747{fill:none;stroke:url(#SVGID_767_);stroke-width:0.5;stroke-miterlimit:10;}
	.st748{fill:none;stroke:url(#SVGID_768_);stroke-width:0.5;stroke-miterlimit:10;}
	.st749{fill:none;stroke:url(#SVGID_769_);stroke-width:0.5;stroke-miterlimit:10;}
	.st750{fill:none;stroke:url(#SVGID_770_);stroke-width:0.5;stroke-miterlimit:10;}
	.st751{fill:none;stroke:url(#SVGID_771_);stroke-width:0.5;stroke-miterlimit:10;}
	.st752{fill:none;stroke:url(#SVGID_772_);stroke-width:0.5;stroke-miterlimit:10;}
	.st753{fill:none;stroke:url(#SVGID_773_);stroke-width:0.5;stroke-miterlimit:10;}
	.st754{fill:none;stroke:url(#SVGID_774_);stroke-width:0.5;stroke-miterlimit:10;}
	.st755{fill:none;stroke:url(#SVGID_775_);stroke-width:0.5;stroke-miterlimit:10;}
	.st756{fill:none;stroke:url(#SVGID_776_);stroke-width:0.5;stroke-miterlimit:10;}
	.st757{fill:none;stroke:url(#SVGID_777_);stroke-width:0.5;stroke-miterlimit:10;}
	.st758{fill:none;stroke:url(#SVGID_778_);stroke-width:0.5;stroke-miterlimit:10;}
	.st759{fill:none;stroke:url(#SVGID_779_);stroke-width:0.5;stroke-miterlimit:10;}
	.st760{fill:none;stroke:url(#SVGID_780_);stroke-width:0.5;stroke-miterlimit:10;}
	.st761{fill:none;stroke:url(#SVGID_781_);stroke-width:0.5;stroke-miterlimit:10;}
	.st762{fill:none;stroke:url(#SVGID_782_);stroke-width:0.5;stroke-miterlimit:10;}
	.st763{fill:none;stroke:url(#SVGID_783_);stroke-width:0.5;stroke-miterlimit:10;}
	.st764{fill:none;stroke:url(#SVGID_784_);stroke-width:0.5;stroke-miterlimit:10;}
	.st765{fill:none;stroke:url(#SVGID_785_);stroke-width:0.5;stroke-miterlimit:10;}
	.st766{fill:none;stroke:url(#SVGID_786_);stroke-width:0.5;stroke-miterlimit:10;}
	.st767{fill:none;stroke:url(#SVGID_787_);stroke-width:0.5;stroke-miterlimit:10;}
	.st768{fill:none;stroke:url(#SVGID_788_);stroke-width:0.5;stroke-miterlimit:10;}
	.st769{fill:none;stroke:url(#SVGID_789_);stroke-width:0.5;stroke-miterlimit:10;}
	.st770{fill:none;stroke:url(#SVGID_790_);stroke-width:0.5;stroke-miterlimit:10;}
	.st771{fill:none;stroke:url(#SVGID_791_);stroke-width:0.5;stroke-miterlimit:10;}
	.st772{fill:none;stroke:url(#SVGID_792_);stroke-width:0.5;stroke-miterlimit:10;}
	.st773{fill:none;stroke:url(#SVGID_793_);stroke-width:0.5;stroke-miterlimit:10;}
	.st774{fill:none;stroke:url(#SVGID_794_);stroke-width:0.5;stroke-miterlimit:10;}
	.st775{fill:none;stroke:url(#SVGID_795_);stroke-width:0.5;stroke-miterlimit:10;}
	.st776{fill:none;stroke:url(#SVGID_796_);stroke-width:0.5;stroke-miterlimit:10;}
	.st777{fill:none;stroke:url(#SVGID_797_);stroke-width:0.5;stroke-miterlimit:10;}
	.st778{fill:none;stroke:url(#SVGID_798_);stroke-width:0.5;stroke-miterlimit:10;}
	.st779{fill:none;stroke:url(#SVGID_799_);stroke-width:0.5;stroke-miterlimit:10;}
	.st780{fill:none;stroke:url(#SVGID_800_);stroke-width:0.5;stroke-miterlimit:10;}
	.st781{clip-path:url(#SVGID_802_);}
	.st782{fill:none;stroke:url(#SVGID_803_);stroke-width:2;stroke-miterlimit:10;}
	.st783{fill:none;stroke:url(#SVGID_804_);stroke-width:2;stroke-miterlimit:10;}
	.st784{fill:none;stroke:url(#SVGID_805_);stroke-width:2;stroke-miterlimit:10;}
	.st785{fill:none;stroke:url(#SVGID_806_);stroke-width:2;stroke-miterlimit:10;}
	.st786{fill:none;stroke:url(#SVGID_807_);stroke-width:2;stroke-miterlimit:10;}
	.st787{fill:none;stroke:url(#SVGID_808_);stroke-width:2;stroke-miterlimit:10;}
	.st788{fill:none;stroke:url(#SVGID_809_);stroke-width:2;stroke-miterlimit:10;}
	.st789{fill:none;stroke:url(#SVGID_810_);stroke-width:2;stroke-miterlimit:10;}
	.st790{fill:none;stroke:url(#SVGID_811_);stroke-width:2;stroke-miterlimit:10;}
	.st791{fill:none;stroke:url(#SVGID_812_);stroke-width:2;stroke-miterlimit:10;}
	.st792{display:none;}
	.st793{display:inline;fill:#DADADA;}
	.st794{fill:none;stroke:url(#SVGID_813_);stroke-width:2;stroke-miterlimit:10;}
	.st795{opacity:0.16;clip-path:url(#SVGID_815_);}
	.st796{fill:none;stroke:url(#SVGID_816_);stroke-width:2;stroke-miterlimit:10;}
	.st797{fill:none;stroke:url(#SVGID_817_);stroke-width:2;stroke-miterlimit:10;}
	.st798{fill:none;stroke:url(#SVGID_818_);stroke-width:2;stroke-miterlimit:10;}
	.st799{fill:none;stroke:url(#SVGID_819_);stroke-width:2;stroke-miterlimit:10;}
	.st800{fill:none;stroke:url(#SVGID_820_);stroke-width:2;stroke-miterlimit:10;}
	.st801{fill:none;stroke:url(#SVGID_821_);stroke-width:2;stroke-miterlimit:10;}
	.st802{fill:none;stroke:url(#SVGID_822_);stroke-width:2;stroke-miterlimit:10;}
	.st803{fill:none;stroke:url(#SVGID_823_);stroke-width:2;stroke-miterlimit:10;}
	.st804{fill:none;stroke:url(#SVGID_824_);stroke-width:2;stroke-miterlimit:10;}
	.st805{fill:none;stroke:url(#SVGID_825_);stroke-width:2;stroke-miterlimit:10;}
	.st806{fill:none;stroke:url(#SVGID_826_);stroke-width:2;stroke-miterlimit:10;}
	.st807{fill:none;stroke:url(#SVGID_827_);stroke-width:2;stroke-miterlimit:10;}
	.st808{fill:none;stroke:url(#SVGID_828_);stroke-width:2;stroke-miterlimit:10;}
	.st809{fill:none;stroke:url(#SVGID_829_);stroke-width:2;stroke-miterlimit:10;}
	.st810{fill:none;stroke:url(#SVGID_830_);stroke-width:2;stroke-miterlimit:10;}
	.st811{fill:none;stroke:url(#SVGID_831_);stroke-width:2;stroke-miterlimit:10;}
	.st812{fill:none;stroke:url(#SVGID_832_);stroke-width:2;stroke-miterlimit:10;}
	.st813{fill:none;stroke:url(#SVGID_833_);stroke-width:2;stroke-miterlimit:10;}
	.st814{fill:url(#SVGID_834_);}
	.st815{fill:url(#SVGID_835_);}
	.st816{opacity:0.07;clip-path:url(#SVGID_837_);}
	.st817{opacity:0.4;clip-path:url(#SVGID_837_);fill:url(#SVGID_838_);}
	.st818{fill:url(#SVGID_839_);}
	.st819{fill:url(#SVGID_840_);}
	.st820{fill:url(#SVGID_841_);}
	.st821{fill:url(#SVGID_842_);}
	.st822{clip-path:url(#SVGID_844_);}
	.st823{fill:none;stroke:url(#SVGID_845_);stroke-width:0.5;stroke-miterlimit:10;}
	.st824{fill:none;stroke:url(#SVGID_846_);stroke-width:0.5;stroke-miterlimit:10;}
	.st825{fill:none;stroke:url(#SVGID_847_);stroke-width:0.5;stroke-miterlimit:10;}
	.st826{fill:none;stroke:url(#SVGID_848_);stroke-width:0.5;stroke-miterlimit:10;}
	.st827{fill:none;stroke:url(#SVGID_849_);stroke-width:0.5;stroke-miterlimit:10;}
	.st828{fill:none;stroke:url(#SVGID_850_);stroke-width:0.5;stroke-miterlimit:10;}
	.st829{fill:none;stroke:url(#SVGID_851_);stroke-width:0.5;stroke-miterlimit:10;}
	.st830{fill:none;stroke:url(#SVGID_852_);stroke-width:0.5;stroke-miterlimit:10;}
	.st831{fill:none;stroke:url(#SVGID_853_);stroke-width:0.5;stroke-miterlimit:10;}
	.st832{fill:none;stroke:url(#SVGID_854_);stroke-width:0.5;stroke-miterlimit:10;}
	.st833{fill:none;stroke:url(#SVGID_855_);stroke-width:0.5;stroke-miterlimit:10;}
	.st834{fill:none;stroke:url(#SVGID_856_);stroke-width:0.5;stroke-miterlimit:10;}
	.st835{fill:none;stroke:url(#SVGID_857_);stroke-width:0.5;stroke-miterlimit:10;}
	.st836{fill:none;stroke:url(#SVGID_858_);stroke-width:0.5;stroke-miterlimit:10;}
	.st837{fill:none;stroke:url(#SVGID_859_);stroke-width:0.5;stroke-miterlimit:10;}
	.st838{fill:none;stroke:url(#SVGID_860_);stroke-width:0.5;stroke-miterlimit:10;}
	.st839{fill:none;stroke:url(#SVGID_861_);stroke-width:0.5;stroke-miterlimit:10;}
	.st840{fill:none;stroke:url(#SVGID_862_);stroke-width:0.5;stroke-miterlimit:10;}
	.st841{fill:none;stroke:url(#SVGID_863_);stroke-width:0.5;stroke-miterlimit:10;}
	.st842{fill:none;stroke:url(#SVGID_864_);stroke-width:0.5;stroke-miterlimit:10;}
	.st843{fill:none;stroke:url(#SVGID_865_);stroke-width:0.5;stroke-miterlimit:10;}
	.st844{fill:none;stroke:url(#SVGID_866_);stroke-width:0.5;stroke-miterlimit:10;}
	.st845{fill:none;stroke:url(#SVGID_867_);stroke-width:0.5;stroke-miterlimit:10;}
	.st846{fill:none;stroke:url(#SVGID_868_);stroke-width:0.5;stroke-miterlimit:10;}
	.st847{fill:none;stroke:url(#SVGID_869_);stroke-width:0.5;stroke-miterlimit:10;}
	.st848{fill:none;stroke:url(#SVGID_870_);stroke-width:0.5;stroke-miterlimit:10;}
	.st849{fill:none;stroke:url(#SVGID_871_);stroke-width:0.5;stroke-miterlimit:10;}
	.st850{fill:none;stroke:url(#SVGID_872_);stroke-width:0.5;stroke-miterlimit:10;}
	.st851{fill:none;stroke:url(#SVGID_873_);stroke-width:0.5;stroke-miterlimit:10;}
	.st852{fill:none;stroke:url(#SVGID_874_);stroke-width:0.5;stroke-miterlimit:10;}
	.st853{fill:none;stroke:url(#SVGID_875_);stroke-width:0.5;stroke-miterlimit:10;}
	.st854{fill:none;stroke:url(#SVGID_876_);stroke-width:0.5;stroke-miterlimit:10;}
	.st855{fill:none;stroke:url(#SVGID_877_);stroke-width:0.5;stroke-miterlimit:10;}
	.st856{fill:none;stroke:url(#SVGID_878_);stroke-width:0.5;stroke-miterlimit:10;}
	.st857{fill:none;stroke:url(#SVGID_879_);stroke-width:0.5;stroke-miterlimit:10;}
	.st858{fill:none;stroke:url(#SVGID_880_);stroke-width:0.5;stroke-miterlimit:10;}
	.st859{fill:none;stroke:url(#SVGID_881_);stroke-width:0.5;stroke-miterlimit:10;}
	.st860{fill:none;stroke:url(#SVGID_882_);stroke-width:0.5;stroke-miterlimit:10;}
	.st861{fill:none;stroke:url(#SVGID_883_);stroke-width:0.5;stroke-miterlimit:10;}
	.st862{fill:none;stroke:url(#SVGID_884_);stroke-width:0.5;stroke-miterlimit:10;}
	.st863{fill:none;stroke:url(#SVGID_885_);stroke-width:0.5;stroke-miterlimit:10;}
	.st864{fill:none;stroke:url(#SVGID_886_);stroke-width:0.5;stroke-miterlimit:10;}
	.st865{fill:none;stroke:url(#SVGID_887_);stroke-width:3;stroke-miterlimit:10;}
	.st866{fill:none;stroke:url(#SVGID_888_);stroke-width:3;stroke-miterlimit:10;}
	.st867{fill:none;stroke:url(#SVGID_889_);stroke-width:3;stroke-miterlimit:10;}
	.st868{fill:none;stroke:url(#SVGID_890_);stroke-width:3;stroke-miterlimit:10;}
	.st869{fill:none;stroke:url(#SVGID_891_);stroke-width:3;stroke-miterlimit:10;}
	.st870{fill:none;stroke:url(#SVGID_892_);stroke-width:3;stroke-miterlimit:10;}
	.st871{fill:none;stroke:url(#SVGID_893_);stroke-width:3;stroke-miterlimit:10;}
	.st872{fill:url(#SVGID_894_);}
	.st873{fill:none;stroke:url(#SVGID_895_);stroke-width:3;stroke-miterlimit:10;}
	.st874{clip-path:url(#SVGID_897_);}
	.st875{opacity:0.51;fill:none;stroke:url(#SVGID_898_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st876{opacity:0.51;fill:none;stroke:url(#SVGID_899_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st877{opacity:0.82;fill:none;stroke:url(#SVGID_900_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st878{opacity:0.51;fill:none;stroke:url(#SVGID_901_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st879{opacity:0.51;fill:none;stroke:url(#SVGID_902_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st880{opacity:0.51;fill:none;stroke:url(#SVGID_903_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st881{opacity:0.82;fill:none;stroke:url(#SVGID_904_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st882{opacity:0.51;fill:none;stroke:url(#SVGID_905_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st883{fill:url(#SVGID_906_);}
	.st884{fill:url(#SVGID_907_);stroke:url(#SVGID_908_);stroke-width:3;stroke-miterlimit:10;}
	.st885{clip-path:url(#SVGID_910_);}
	.st886{opacity:0.51;fill:none;stroke:url(#SVGID_911_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st887{opacity:0.51;fill:none;stroke:url(#SVGID_912_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st888{opacity:0.82;fill:none;stroke:url(#SVGID_913_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st889{opacity:0.51;fill:none;stroke:url(#SVGID_914_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st890{opacity:0.51;fill:none;stroke:url(#SVGID_915_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st891{opacity:0.51;fill:none;stroke:url(#SVGID_916_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st892{opacity:0.82;fill:none;stroke:url(#SVGID_917_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st893{opacity:0.51;fill:none;stroke:url(#SVGID_918_);stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}`}
        </style>
        <g>
          <line class="st303" x1="326.59" y1="277.92" x2="374.41" y2="277.92" />
          <line class="st303" x1="376.57" y1="277.92" x2="383.06" y2="277.92" />
          <line class="st303" x1="309.65" y1="283.37" x2="357.46" y2="283.37" />
          <line class="st303" x1="359.63" y1="283.37" x2="366.12" y2="283.37" />
          <line class="st303" x1="368.48" y1="283.37" x2="416.29" y2="283.37" />
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="104.0155"
            y1="164.6351"
            x2="386.6253"
            y2="327.8"
          >
            <stop offset="0" style={{ stopColor: '#4D0800' }} />
            <stop offset="0.1708" style={{ stopColor: '#2D0100' }} />
            <stop offset="0.4912" style={{ stopColor: '#000000' }} />
          </linearGradient>
          <circle class="st1" cx="245.32" cy="246.22" r="163.14" />
          <g>
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="95.9048"
              y1="245.5066"
              x2="393.6978"
              y2="245.5066"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>
            <path
              class="st2"
              d="M244.8,394.4c-82.1,0-148.9-66.79-148.9-148.9s66.79-148.9,148.9-148.9s148.9,66.79,148.9,148.9
			S326.9,394.4,244.8,394.4z M244.8,99.61c-80.45,0-145.9,65.45-145.9,145.9s65.45,145.9,145.9,145.9s145.9-65.45,145.9-145.9
			S325.25,99.61,244.8,99.61z"
            />
          </g>
          <g>
            <g>
              <g>
                <rect
                  x="242.42"
                  y="83.08"
                  class="st517"
                  width="3"
                  height="14.41"
                />
              </g>
              <g>
                <rect
                  x="242.42"
                  y="394.94"
                  class="st517"
                  width="3"
                  height="14.41"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="393.53"
                  y="247.27"
                  class="st517"
                  width="14.41"
                  height="3"
                />
              </g>
              <g>
                <rect
                  x="81.66"
                  y="247.27"
                  class="st517"
                  width="14.41"
                  height="3"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="162.01"
                  y="107.77"
                  transform="matrix(0.866 -0.5 0.5 0.866 -35.5796 97.1545)"
                  class="st517"
                  width="3"
                  height="14.41"
                />
              </g>
              <g>
                <rect
                  x="317.94"
                  y="377.85"
                  transform="matrix(0.866 -0.5 0.5 0.866 -149.7277 211.3009)"
                  class="st517"
                  width="3"
                  height="14.41"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="107.81"
                  y="163.38"
                  transform="matrix(0.5 -0.866 0.866 0.5 -93.0764 179.9581)"
                  class="st517"
                  width="3"
                  height="14.41"
                />
              </g>
              <g>
                <rect
                  x="377.9"
                  y="319.31"
                  transform="matrix(0.5 -0.866 0.866 0.5 -93.0713 491.8403)"
                  class="st517"
                  width="3"
                  height="14.41"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="158.75"
                  y="378.38"
                  transform="matrix(0.5001 -0.866 0.866 0.5001 -246.0038 333.6265)"
                  class="st517"
                  width="14.41"
                  height="3"
                />
              </g>
              <g>
                <rect
                  x="314.68"
                  y="108.29"
                  transform="matrix(0.5001 -0.866 0.866 0.5001 65.8417 333.6373)"
                  class="st517"
                  width="14.41"
                  height="3"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="372.99"
                  y="166.75"
                  transform="matrix(0.866 -0.5001 0.5001 0.866 -33.1867 212.6661)"
                  class="st517"
                  width="14.41"
                  height="3"
                />
              </g>
              <g>
                <rect
                  x="102.9"
                  y="322.68"
                  transform="matrix(0.866 -0.5001 0.5001 0.866 -147.3558 98.5048)"
                  class="st517"
                  width="14.41"
                  height="3"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              class="st10"
              d="M210.29,270.16c0.65-0.34,1.29-0.7,1.96-1c3.28-1.47,6.6-2.89,9.85-4.42c0.79-0.37,1.75-1,2.04-1.74
			c4.23-10.66,8.36-21.37,12.63-32.38c-3.62-0.34-7.05,0-11.07-0.11c0.83-0.67,1.11-0.99,1.45-1.16c8.99-4.52,18.47-6.14,28.43-4.58
			c1.56,0.25,3.12,0.98,4.49,1.81c3,1.83,3.95,4.94,2.21,8c-1.12,1.96-2.85,3.59-4.38,5.3c-0.56,0.63-1.31,1.08-2.19,1.79
			c0.69,0.4,1.26,0.68,1.78,1.04c1.61,1.12,3.39,2.07,4.74,3.44c1.96,1.98,2.17,4.58,0.5,6.81c-1.36,1.82-3.05,3.5-4.89,4.84
			c-4.98,3.63-10.71,5.71-16.55,7.49c-9.8,3-19.87,4.48-30.05,5.33c-0.25,0.02-0.5-0.05-0.75-0.08
			C210.42,270.42,210.36,270.29,210.29,270.16z M250.69,250.05c-0.69-0.35-1.2-0.81-1.72-0.83c-2.81-0.08-5.63-0.06-8.44-0.02
			c-0.35,0.01-0.88,0.31-1.02,0.61c-1.21,2.67-2.33,5.39-3.35,8.37C242.2,256.6,248.99,252.84,250.69,250.05z M244.03,238.38
			c3.3,0.11,7.93-2.34,9.01-4.77c-1.52-1.91-3.79-1.93-6.12-2.37C245.94,233.65,245,235.99,244.03,238.38z"
            />
            <path
              class="st10"
              d="M219.46,273.78c0.63,0.34,1.08,0.53,1.46,0.8c14.86,10.51,32.19,6.97,43.96-3.7
			c5.81-5.27,9.91-11.69,11.17-19.52c2.03-12.61-1.93-23.32-11.59-31.55c-7.38-6.29-16.19-6.97-25.3-4.55
			c-14.44,3.85-25.76,16.86-27.6,31.72c-0.37,2.97-0.32,5.98-0.45,8.98c-0.04,0.84-0.01,1.68-0.49,2.68
			c-0.24-0.63-0.56-1.24-0.7-1.89c-2.74-12.3-0.4-23.57,7.31-33.51c7.84-10.11,18.26-15.84,31.11-16.24
			c11.2-0.35,20.33,4.32,27.15,13.21c5.3,6.92,8.36,14.8,8.57,23.56c0.27,11.63-4.19,21.42-12.52,29.41
			c-7.52,7.22-16.53,11.38-27,11.74c-9.54,0.32-17.82-2.99-24.63-9.78c-0.18-0.18-0.32-0.4-0.46-0.61
			C219.43,274.45,219.46,274.34,219.46,273.78z"
            />
          </g>
          <circle class="st13" cx="155.35" cy="154.62" r="3.72" />
          <circle class="st13" cx="324.89" cy="338.66" r="3.72" />
          <g>
            <defs>
              <circle id="SVGID_641_" cx="245.3" cy="247.77" r="163.14" />
            </defs>
            <clipPath id="SVGID_3_">
              <use style={{ overflow: 'visible' }} />
            </clipPath>
            <g style={{ clipPath: 'url(#SVGID_3_)' }}>
              <g>
                <linearGradient
                  id="SVGID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="2230.5781"
                  y1="-694.9714"
                  x2="2300.6584"
                  y2="-694.9714"
                  gradientTransform="matrix(0.9782 -0.2075 0.2075 0.9782 -1895.3633 1494.7927)"
                >
                  <stop
                    offset="0.0232"
                    style={{ stopColor: '#2581C4;stop-opacity:0' }}
                  />
                  <stop
                    offset="0.1375"
                    style={{ stopColor: '#3776B6;stop-opacity:0.1171' }}
                  />
                  <stop
                    offset="0.3668"
                    style={{ stopColor: '#665B92;stop-opacity:0.3518' }}
                  />
                  <stop
                    offset="0.6862"
                    style={{ stopColor: '#B02F59;stop-opacity:0.6788' }}
                  />
                  <stop offset="1" style={{ stopColor: '#FF001C' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.51',
                    fill: 'none',
                    stroke: 'url(#SVGID_4_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="201.77"
                  y1="306.16"
                  x2="151.61"
                  y2="383.33"
                />

                <linearGradient
                  id="SVGID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1="2235.4302"
                  y1="-671.549"
                  x2="2305.5105"
                  y2="-671.549"
                  gradientTransform="matrix(0.9782 -0.2075 0.2075 0.9782 -1895.3633 1494.7927)"
                >
                  <stop
                    offset="0.0232"
                    style={{ stopColor: '#2581C4;stop-opacity:0' }}
                  />
                  <stop
                    offset="0.1175"
                    style={{ stopColor: '#2C7DBE;stop-opacity:0.0965' }}
                  />
                  <stop
                    offset="0.2596"
                    style={{ stopColor: '#4170AE;stop-opacity:0.242' }}
                  />
                  <stop
                    offset="0.4318"
                    style={{ stopColor: '#635C94;stop-opacity:0.4183' }}
                  />
                  <stop
                    offset="0.6275"
                    style={{ stopColor: '#924070;stop-opacity:0.6187' }}
                  />
                  <stop
                    offset="0.8403"
                    style={{ stopColor: '#CD1D42;stop-opacity:0.8365' }}
                  />
                  <stop offset="1" style={{ stopColor: '#FF001C' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.51',
                    fill: 'none',
                    stroke: 'url(#SVGID_6_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="211.38"
                  y1="328.07"
                  x2="161.22"
                  y2="405.24"
                />
                <linearGradient
                  id="SVGID_7_"
                  gradientUnits="userSpaceOnUse"
                  x1="171.0142"
                  y1="384.7972"
                  x2="226.1707"
                  y2="384.7972"
                >
                  <stop
                    offset="0.0711"
                    style={{ stopColor: '#1D1D1B;stop-opacity:0' }}
                  />
                  <stop offset="0.9289" style={{ stopColor: '#FFFFFF' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.82',
                    fill: 'none',
                    stroke: 'url(#SVGID_7_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="223.67"
                  y1="346.21"
                  x2="173.51"
                  y2="423.38"
                />

                <linearGradient
                  id="SVGID_8_"
                  gradientUnits="userSpaceOnUse"
                  x1="2198.1453"
                  y1="-690.5435"
                  x2="2268.2256"
                  y2="-690.5435"
                  gradientTransform="matrix(0.9782 -0.2075 0.2075 0.9782 -1895.3633 1494.7927)"
                >
                  <stop
                    offset="0.0711"
                    style={{ stopColor: '#1D1D1B;stop-opacity:0' }}
                  />
                  <stop offset="0.9289" style={{ stopColor: '#FFFFFF' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.51',
                    fill: 'none',
                    stroke: 'url(#SVGID_8_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="170.96"
                  y1="317.23"
                  x2="120.8"
                  y2="394.4"
                />
              </g>
              <g>
                <linearGradient
                  id="SVGID_9_"
                  gradientUnits="userSpaceOnUse"
                  x1="2383.6729"
                  y1="-855.2659"
                  x2="2453.7532"
                  y2="-855.2659"
                  gradientTransform="matrix(0.9782 -0.2075 0.2075 0.9782 -1895.3633 1494.7927)"
                >
                  <stop
                    offset="0.0232"
                    style={{ stopColor: '#2581C4;stop-opacity:0' }}
                  />
                  <stop
                    offset="0.1375"
                    style={{ stopColor: '#3776B6;stop-opacity:0.1171' }}
                  />
                  <stop
                    offset="0.3668"
                    style={{ stopColor: '#665B92;stop-opacity:0.3518' }}
                  />
                  <stop
                    offset="0.6862"
                    style={{ stopColor: '#B02F59;stop-opacity:0.6788' }}
                  />
                  <stop offset="1" style={{ stopColor: '#FF001C' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.51',
                    fill: 'none',
                    stroke: 'url(#SVGID_9_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="318.26"
                  y1="117.59"
                  x2="268.11"
                  y2="194.76"
                />

                <linearGradient
                  id="SVGID_11_"
                  gradientUnits="userSpaceOnUse"
                  x1="2388.5249"
                  y1="-831.8435"
                  x2="2458.6052"
                  y2="-831.8435"
                  gradientTransform="matrix(0.9782 -0.2075 0.2075 0.9782 -1895.3633 1494.7927)"
                >
                  <stop
                    offset="0.0232"
                    style={{ stopColor: '#2581C4;stop-opacity:0' }}
                  />
                  <stop
                    offset="0.1175"
                    style={{ stopColor: '#2C7DBE;stop-opacity:0.0965' }}
                  />
                  <stop
                    offset="0.2596"
                    style={{ stopColor: '#4170AE;stop-opacity:0.242' }}
                  />
                  <stop
                    offset="0.4318"
                    style={{ stopColor: '#635C94;stop-opacity:0.4183' }}
                  />
                  <stop
                    offset="0.6275"
                    style={{ stopColor: '#924070;stop-opacity:0.6187' }}
                  />
                  <stop
                    offset="0.8403"
                    style={{ stopColor: '#CD1D42;stop-opacity:0.8365' }}
                  />
                  <stop offset="1" style={{ stopColor: '#FF001C' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.51',
                    fill: 'none',
                    stroke: 'url(#SVGID_11_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="327.87"
                  y1="139.49"
                  x2="277.71"
                  y2="216.66"
                />
                <linearGradient
                  id="SVGID_12_"
                  gradientUnits="userSpaceOnUse"
                  x1="287.508"
                  y1="196.2196"
                  x2="342.6646"
                  y2="196.2196"
                >
                  <stop
                    offset="0.0711"
                    style={{ stopColor: '#1D1D1B;stop-opacity:0' }}
                  />
                  <stop offset="0.9289" style={{ stopColor: '#FFFFFF' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.82',
                    fill: 'none',
                    stroke: 'url(#SVGID_12_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="340.16"
                  y1="157.63"
                  x2="290.01"
                  y2="234.8"
                />

                <linearGradient
                  id="SVGID_13_"
                  gradientUnits="userSpaceOnUse"
                  x1="2351.24"
                  y1="-850.838"
                  x2="2421.3203"
                  y2="-850.838"
                  gradientTransform="matrix(0.9782 -0.2075 0.2075 0.9782 -1895.3633 1494.7927)"
                >
                  <stop
                    offset="0.0711"
                    style={{ stopColor: '#1D1D1B;stop-opacity:0' }}
                  />
                  <stop offset="0.9289" style={{ stopColor: '#FFFFFF' }} />
                </linearGradient>

                <line
                  style={{
                    opacity: '0.51',
                    fill: 'none',
                    stroke: 'url(#SVGID_13_)',
                    strokeWidth: '5',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10'
                  }}
                  x1="287.46"
                  y1="128.65"
                  x2="237.3"
                  y2="205.82"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
