import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useContext
} from 'react'
import { DrawerContext } from 'components/Drawer'
import styled from 'styled-components'
import Router, { useRouter } from 'next/router'
import useUserData from 'hooks/useUserData'
import { useCollection, useDocument } from '@sb-konzept/firebase-hooks'
import moment from 'moment'
import Close from 'images/close.png'
import { FieldValue } from 'utils/firebase'
import WorkoutStreak from 'components/Badges/WorkoutStreak'
import MemberStreak from 'components/Badges/MemberStreak'
import WeightStreak from 'components/Badges/WeightStreak'
import Button from 'components/Button'
import LogoSmall from './logosmall'
import html2canvas from 'html2canvas'
import Logo from 'images/logo.png'

const Backdrop = styled.div`
  position: fixed;
  z-index: 99999;
  top: env(safe-area-inset-top);
  left: 0;
  height: calc(
    100vh - 3.875rem - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
  width: 100vw;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: ' ';
    background: url('/images/badgebg.jpg');
    background-size: cover;
    opacity: 0.2;
  }
`

const Modal = styled.div`
  box-sizing: border-box;
  width: 90%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #2c2c2c;
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 40px;
  > img,
  svg {
    height: 180px;
    width: 100%;
    object-fit: contain;
  }
  h1 {
    font-size: 1.375rem;
    font-weight: bold;
    padding-bottom: 1.5rem;
    color: #ffffff;
  }
  p {
    font-weight: light;
    font-size: 1rem;
    line-height: 1.2em;
    text-align: center;
    color: #939393;
    padding-bottom: 1.5rem;
  }
`

const FancyModal = styled.div`
  height: calc(100vh - 140px);
  width: calc(100vw - 24px);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  h1 {
    font-size: 32px;
    margin-bottom: 1rem;
  }
  p {
    color: #939393;
  }
  > img {
    width: 55vw;
  }
  a {
    display: flex;
    align-items: center;
    padding: 16px 0 0 0;
    font-weight: bold;
  }
`

const Badges = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 0 0;
  overflow-y: scroll;
  position: relative;
`

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  min-height: 33%;
  width: 50%;
  img,
  svg {
    width: 50%;
    height: auto;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
`

const BadgeText = styled.div``

const Context = React.createContext()
export default Context

export const BADGES = {
  personalRecord: {
    content: ss => ({
      icon: '/images/personalrecord-1.svg',
      title: 'New Personal Record',
      headline: 'Congratulations!',
      text: ss.badgeText,
      workoutText: ss.badgeText,
      workoutTitle: 'New PR!'
    })
  },
  workoutStreak: {
    levels: [1, 5, 10, 15, 25, 50, 75, 100, 125, 150, 175, 200, 250, 300, 500],
    content: level => ({
      icon: <WorkoutStreak level={level} />,
      title: level > 1 ? `${level} Workouts` : 'First Workout',
      headline: 'Nice Work!',
      text: `You've completed ${
        level > 100
          ? `${level} Beastly workouts`
          : level > 1
          ? `your ${level}th Beastly Workout`
          : 'your first Beastly Workout'
      } `,
      workoutTitle: 'New Streak!',
      workoutText: `You completed your ${level}${
        level > 1 ? 'th' : 'st'
      } Workout`
    })
  },
  memberstreak: {
    levels: [3, 6, 12, 24],
    content: (level, gender) => ({
      icon: <MemberStreak gender={gender} level={level} />,
      title:
        level > 12
          ? `2 Year Member `
          : level > 6
          ? `1 Year Member`
          : `${level} Month Member`,
      headline: 'Keep it going!',
      text: `You've been a Beastly App member for ${level} months!`
    })
  },
  weightSteak: {
    levels: [5, 10, 15, 20, 30, 40, 50],
    content: level => ({
      icon: <WeightStreak level={level} />,
      title: `${level}lbs Lost`,
      headline: 'Hard work pays off!',
      text: `You've lost ${level}lbs`
    })
  },
  mealMaster: {
    levels: [1],
    content: level => ({
      icon: '/images/meal-beastkitchen.svg',
      title: 'Meal Master',
      headline: `You're on a roll!`,
      text: `You've created 25 new meals.`
    })
  },
  beastInTheKitchen: {
    levels: [1],
    content: level => ({
      icon: '/images/meal-recipes.svg',
      title: 'Beast in the kitchen',
      headline: 'Great job!',
      text: `You've made 10 Beastly Recipes`
    })
  },
  mealStreak: {
    levels: [1, 3, 6, 10, 15, 18, 21],
    content: level => ({
      icon: 'images/diet-macros.svg',
      title: level > 1 ? `${level} Day Streak` : 'Perfect Macros',
      headline: 'Great Work!',
      text:
        level > 1
          ? `You've tracked perfect macros for ${level} 
      days in a row `
          : 'Your nutrition has been on point for a full day.'
    })
  },
  program1: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-1.svg',
      title: 'Bodyweight Beast',
      headline: 'Congratulations!',
      text: "You've completed the Bodyweight Beast Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Bodyweight Beast Program."
    })
  },
  program2: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-2.svg',
      title: 'Beginner Full Body',
      headline: 'Congratulations!',
      text: "You've completed the Beginner Full Body Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Beginner Full Body Program."
    })
  },
  program3: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-3.svg',
      title: 'Intermediate Full Body',
      headline: 'Congratulations!',
      text: "You've completed the Intermediate Full Body Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Intermediate Full Body Program."
    })
  },
  program4: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-4.svg',
      title: 'Push/Pull/Legs',
      headline: 'Congratulations!',
      text: "You've completed the Push/Pull/Legs Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Push/Pull/Legs Program."
    })
  },
  program5: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-5.svg',
      title: 'Upper/Lower Size + Strength',
      headline: 'Congratulations!',
      text: "You've completed the Upper/Lower Size + Strength Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Upper/Lower Size + Strength Program."
    })
  },
  program6: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-6.svg',
      title: 'Dumbbell Full Body',
      headline: 'Congratulations!',
      text: "You've completed the Dumbbell Full Body Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Dumbbell Full Body Program."
    })
  },
  program7: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-7.svg',
      title: 'Body Weight Beast',
      headline: 'Congratulations!',
      text: "You've completed the Body Weight Beast Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Body Weight Beast Program."
    })
  },
  program8: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-8.svg',
      title: 'Booty Beast',
      headline: 'Congratulations!',
      text: "You've completed the Booty Beast Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Booty Beast Program."
    })
  },
  program9: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-9.svg',
      title: 'PPL Full Body Hybrid',
      headline: 'Congratulations!',
      text: "You've completed the PPL Full Body Hybrid Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the PPL Full Body Hybrid Program."
    })
  },
  program10: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-10.svg',
      title: 'Women Beginner Full Body',
      headline: 'Congratulations!',
      text: "You've completed the Women Beginner Full Body Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Women Beginner Full Body Program."
    })
  },
  program11: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-11.svg',
      title: 'Advanced 3 Day Full Body',
      headline: 'Congratulations!',
      text: "You've completed the Advanced 3 Day Full Body Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Advanced 3 Day Full Body Program."
    })
  },
  program12: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-12.svg',
      title: 'Ultimate Bro Split',
      headline: 'Congratulations!',
      text: "You've completed the Ultimate Bro Split Program.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the Ultimate Bro Split Program."
    })
  },
  program13: {
    levels: [1],
    content: level => ({
      icon: '/images/programcompletion-13.svg',
      title: '8 Week Shred',
      headline: 'Congratulations!',
      text: "You've completed the 8 Week Shred.",
      workoutTitle: 'Program Completed!',
      workoutText: "You've completed the 8 Week Shred Program."
    })
  }
}

const Drawer = styled.img`
  margin: -1rem;
  padding: 1rem;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 12px;
  left: 12px;
`

export function BadgeProvider({ children }) {
  const { setOpen: openDrawer } = useContext(DrawerContext)
  const { value: user, ref } = useUserData()
  const pathname = useRef()
  const router = useRouter()
  const backdropRef = useRef()
  const [badgeQueue, setBadgeQueue] = useState([])
  const [open, setOpen] = useState(false)

  const showBadge = useCallback((badge, level, show = true) => {
    setBadgeQueue(prev => [...prev, BADGES[badge].content(level)])
    setOpen(!!show)
  }, [])

  const flushBadges = useCallback(() => setOpen(true), [])

  const val = useMemo(() => ({ showBadge, flushBadges }), [
    showBadge,
    flushBadges
  ])

  useEffect(() => {
    if (!badgeQueue.length && open) {
      setOpen(false)
    }
  }, [badgeQueue, open])

  useEffect(() => {
    setOpen(
      router.pathname !== '/workout' &&
        (pathname.current === '/workout' ? 'fancy' : true)
    )
    pathname.current = router.pathname
  }, [router.pathname])

  useEffect(() => {
    const updates = []
    Object.entries(user?.badges || {}).map(([badge, levels]) => {
      if (
        (BADGES[badge]?.levels === 'any' ||
          BADGES[badge]?.levels.includes(levels.level)) &&
        !levels[levels.level]
      ) {
        updates.push(['badges', badge, levels.level].join('.'))
        updates.push(new Date())
        setBadgeQueue(prev => [
          ...prev,
          {
            ...BADGES[badge].content(levels.level, user.gender),
            level: levels.level,
            gender: user.gender,
            key: badge
          }
        ])
        setOpen(router.pathname !== '/workout')
      }
      if (updates.length) ref.update(...updates)
    })
  }, [user, router.pathname])

  return (
    <>
      {false && open && badgeQueue.length && (
        <Backdrop
          ref={backdropRef}
          onClick={() =>
            setBadgeQueue(prev => {
              if (open === 'fancy' && prev.filter(badge => badge.workoutText))
                return prev.filter(badge => !badge.workoutText)
              const [current, ...next] = prev
              return next
            })
          }
        >
          {open === 'fancy' && badgeQueue.filter(badge => badge.workoutText) ? (
            <>
              <Drawer
                src="/images/burger.svg"
                onClick={() => openDrawer(true)}
              />
              <LogoSmall
                height="32"
                width="32"
                style={{ position: 'absolute', right: '12px', top: '12px' }}
              />
              <FancyModal>
                <h1>GAINS BRO</h1>
                <p style={{ marginBottom: '3rem' }}>Workout complete</p>
                <Badges>
                  {badgeQueue
                    .filter(badge => badge.workoutText)
                    .map(badge => (
                      <Badge>
                        {typeof badge.icon === 'string' ? (
                          <img src={badge.icon} />
                        ) : (
                          badge.icon
                        )}
                        <h2>{badge.workoutTitle}</h2>
                        <p>
                          {badge.workoutText
                            .split(':')
                            .reduce((a, v) => [...a, <br />, v], [])
                            .slice(1)}
                        </p>
                      </Badge>
                    ))}
                </Badges>
                <a
                  onClick={e => {
                    e.stopPropagation()
                    html2canvas(backdropRef.current).then(canvas =>
                      window.plugins?.socialsharing?.shareViaInstagram(
                        'Gains Bro!',
                        canvas.toDataURL()
                      )
                    )
                  }}
                >
                  <img
                    src={'/images/ig-icon.png'}
                    style={{
                      height: '1.5rem',
                      width: '1.5rem',
                      marginRight: 8
                    }}
                  ></img>
                  Share on Instagram
                </a>
                <img src={Logo}></img>
              </FancyModal>{' '}
            </>
          ) : (
            <>
              <Modal
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {typeof badgeQueue[0].icon === 'string' ? (
                  <img src={badgeQueue[0].icon} />
                ) : (
                  badgeQueue[0].icon
                )}
                <h1>{badgeQueue[0].headline}</h1>
                <p>{badgeQueue[0].text}</p>
                <div style={{ flex: 1 }} />
                {router.pathname !== '/achievements' && (
                  <Button
                    style={{
                      width: '80%',
                      fontWeight: 'bold',
                      color: '#000000',
                      background: '#ffffff',
                      borderRadius: '10px'
                    }}
                    onClick={() => {
                      setBadgeQueue(prev => {
                        const [current, ...next] = prev
                        return next
                      })
                    }}
                  >
                    Collect Badge
                  </Button>
                )}
              </Modal>
              {/*<div style={{ paddingTop: '20px' }}>
            <button
              style={{
                background: '#3c3c3c',
                color: 'white',
                fontWeight: '0.7rem',
                border: 0,
                borderRadius: '5px',
                padding: '8px 20px'
              }}
              onClick={() => {
                window.plugins?.socialsharing?.shareViaInstagram(
                  'Look at this fancy new badge I got on #BeastlyApp',

                  `https://howtobeast-git-style-ads4u.vercel.app/api/badge.png?key=${badgeQueue[0].key}level=${badgeQueue[0].level}&gender=${badgeQueue[0].gender}`,
                  function() {
                    console.log('share ok')
                  },
                  function(errormsg) {
                    alert(errormsg)
                  }
                )
              }}
            >
              Share on Instagram!
            </button>
            </div>*/}
            </>
          )}
        </Backdrop>
      )}
      <Context.Provider value={val}>{children}</Context.Provider>
    </>
  )
}
