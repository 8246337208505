import { useMemo } from 'react'

export default function WorkoutStreakBadge({ level }) {
  const key = useMemo(() => {
    return Math.random()
  }, [])
  return (
    <svg
      id="Ebene_1"
      style={{ enableBackground: 'new 0 0 500 500' }}
      version="1.1"
      viewBox="0 0 500 500"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <style type="text/css">
        {`.st0{fill:#1D1D1B;}
	.st1{fill:url(#SVGID_1_${key});}
	.st2{fill:url(#SVGID_2_${key});}
	.st3{fill:url(#SVGID_3_${key});}
	.st4{fill:url(#SVGID_4_${key});}
	.st5{opacity:0.6;clip-path:url(#SVGID_6_${key});fill:url(#SVGID_7_${key});}
	.st6{fill:none;stroke:url(#SVGID_8_${key});stroke-width:3;stroke-miterlimit:10;}
	.st7{fill:none;stroke:#DADADA;stroke-width:2;stroke-miterlimit:10;}
	.st8{fill:url(#SVGID_9_${key});}
	.st9{opacity:0.07;clip-path:url(#SVGID_11_${key});}
	.st10{fill:#FFFFFF;}
	.st11{opacity:0.4;clip-path:url(#SVGID_11_${key});fill:url(#SVGID_12_${key});}
	.st12{fill:none;stroke:url(#SVGID_13_${key});stroke-width:3;stroke-miterlimit:10;}
	.st13{fill:#DADADA;}
	.st14{opacity:0.14;fill:#DADADA;}
	.st15{fill:none;stroke:url(#SVGID_14_${key});stroke-width:3;stroke-miterlimit:10;}
	.st16{fill:none;stroke:url(#SVGID_15_${key});stroke-width:3;stroke-miterlimit:10;}
	.st17{fill:none;stroke:url(#SVGID_16_${key});stroke-width:3;stroke-miterlimit:10;}
	.st18{fill:none;stroke:url(#SVGID_17_${key});stroke-width:3;stroke-miterlimit:10;}
	.st19{fill:none;stroke:url(#SVGID_18_${key});stroke-width:3;stroke-miterlimit:10;}
	.st20{fill:none;stroke:url(#SVGID_19_${key});stroke-width:3;stroke-miterlimit:10;}
	.st21{fill:none;stroke:url(#SVGID_20_${key});stroke-width:3;stroke-miterlimit:10;}
	.st22{fill:none;stroke:url(#SVGID_21_${key});stroke-width:3;stroke-miterlimit:10;}
	.st23{fill:none;stroke:url(#SVGID_22_${key});stroke-width:3;stroke-miterlimit:10;}
	.st24{fill:none;stroke:url(#SVGID_23_${key});stroke-width:3;stroke-miterlimit:10;}
	.st25{fill:none;stroke:url(#SVGID_24_${key});stroke-width:3;stroke-miterlimit:10;}
	.st26{fill:none;stroke:url(#SVGID_25_${key});stroke-width:3;stroke-miterlimit:10;}
	.st27{fill:none;stroke:url(#SVGID_26_${key});stroke-width:3;stroke-miterlimit:10;}
	.st28{fill:none;stroke:url(#SVGID_27_${key});stroke-width:3;stroke-miterlimit:10;}
	.st29{fill:none;stroke:url(#SVGID_28_${key});stroke-width:3;stroke-miterlimit:10;}
	.st30{fill:none;stroke:url(#SVGID_29_${key});stroke-width:3;stroke-miterlimit:10;}
	.st31{fill:none;stroke:url(#SVGID_30_${key});stroke-width:3;stroke-miterlimit:10;}
	.st32{fill:none;stroke:url(#SVGID_31_${key});stroke-width:3;stroke-miterlimit:10;}
	.st33{fill:none;stroke:url(#SVGID_32_${key});stroke-width:3;stroke-miterlimit:10;}
	.st34{fill:none;stroke:url(#SVGID_33_${key});stroke-width:3;stroke-miterlimit:10;}
	.st35{fill:none;stroke:url(#SVGID_34_${key});stroke-width:3;stroke-miterlimit:10;}
	.st36{fill:none;stroke:url(#SVGID_35_${key});stroke-width:3;stroke-miterlimit:10;}
	.st37{fill:none;stroke:url(#SVGID_36_${key});stroke-width:3;stroke-miterlimit:10;}
	.st38{fill:none;stroke:url(#SVGID_37_${key});stroke-width:3;stroke-miterlimit:10;}
	.st39{fill:none;stroke:url(#SVGID_38_${key});stroke-width:3;stroke-miterlimit:10;}
	.st40{fill:none;stroke:url(#SVGID_39_${key});stroke-width:3;stroke-miterlimit:10;}
	.st41{fill:none;stroke:url(#SVGID_40_${key});stroke-width:3;stroke-miterlimit:10;}
	.st42{fill:none;stroke:url(#SVGID_41_${key});stroke-width:3;stroke-miterlimit:10;}
	.st43{fill:none;stroke:url(#SVGID_42_${key});stroke-width:3;stroke-miterlimit:10;}
	.st44{fill:none;stroke:url(#SVGID_43_${key});stroke-width:3;stroke-miterlimit:10;}
	.st45{fill:none;stroke:url(#SVGID_44_${key});stroke-width:3;stroke-miterlimit:10;}
	.st46{fill:none;stroke:url(#SVGID_45_${key});stroke-width:3;stroke-miterlimit:10;}
	.st47{fill:none;stroke:url(#SVGID_46_${key});stroke-width:3;stroke-miterlimit:10;}
	.st48{fill:none;stroke:url(#SVGID_47_${key});stroke-width:3;stroke-miterlimit:10;}
	.st49{fill:none;stroke:url(#SVGID_48_${key});stroke-width:3;stroke-miterlimit:10;}
	.st50{fill:none;stroke:url(#SVGID_49_${key});stroke-width:3;stroke-miterlimit:10;}
	.st51{fill:none;stroke:url(#SVGID_50_${key});stroke-width:3;stroke-miterlimit:10;}
	.st52{fill:none;stroke:url(#SVGID_51_${key});stroke-width:3;stroke-miterlimit:10;}
	.st53{fill:none;stroke:url(#SVGID_52_${key});stroke-width:3;stroke-miterlimit:10;}
	.st54{fill:none;stroke:url(#SVGID_53_${key});stroke-width:3;stroke-miterlimit:10;}
	.st55{fill:none;stroke:url(#SVGID_54_${key});stroke-width:3;stroke-miterlimit:10;}
	.st56{fill:none;stroke:url(#SVGID_55_${key});stroke-width:3;stroke-miterlimit:10;}
	.st57{fill:none;stroke:url(#SVGID_56_${key});stroke-width:3;stroke-miterlimit:10;}
	.st58{fill:none;stroke:url(#SVGID_57_${key});stroke-width:3;stroke-miterlimit:10;}
	.st59{fill:none;stroke:url(#SVGID_58_${key});stroke-width:3;stroke-miterlimit:10;}
	.st60{fill:none;stroke:url(#SVGID_59_${key});stroke-width:3;stroke-miterlimit:10;}
	.st61{fill:none;stroke:url(#SVGID_60_${key});stroke-width:3;stroke-miterlimit:10;}
	.st62{fill:none;stroke:url(#SVGID_61_${key});stroke-width:3;stroke-miterlimit:10;}
	.st63{fill:none;stroke:url(#SVGID_62_${key});stroke-width:3;stroke-miterlimit:10;}
	.st64{fill:none;stroke:url(#SVGID_63_${key});stroke-width:3;stroke-miterlimit:10;}
	.st65{fill:none;stroke:url(#SVGID_64_${key});stroke-width:3;stroke-miterlimit:10;}
	.st66{fill:none;stroke:url(#SVGID_65_${key});stroke-width:3;stroke-miterlimit:10;}
	.st67{fill:none;stroke:url(#SVGID_66_${key});stroke-width:3;stroke-miterlimit:10;}
	.st68{fill:none;stroke:url(#SVGID_67_${key});stroke-width:3;stroke-miterlimit:10;}
	.st69{fill:none;stroke:url(#SVGID_68_${key});stroke-width:3;stroke-miterlimit:10;}
	.st70{fill:none;stroke:url(#SVGID_69_${key});stroke-width:3;stroke-miterlimit:10;}
	.st71{fill:none;stroke:url(#SVGID_70_${key});stroke-width:3;stroke-miterlimit:10;}
	.st72{clip-path:url(#SVGID_72_${key});}
	.st73{fill:none;stroke:url(#SVGID_73_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st74{fill:none;stroke:url(#SVGID_74_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st75{fill:none;stroke:url(#SVGID_75_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st76{fill:none;stroke:url(#SVGID_76_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st77{fill:none;stroke:url(#SVGID_77_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st78{fill:none;stroke:url(#SVGID_78_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st79{fill:none;stroke:url(#SVGID_79_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st80{fill:none;stroke:url(#SVGID_80_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st81{fill:none;stroke:url(#SVGID_81_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st82{fill:none;stroke:url(#SVGID_82_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st83{fill:none;stroke:url(#SVGID_83_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st84{fill:none;stroke:url(#SVGID_84_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st85{fill:none;stroke:url(#SVGID_85_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st86{fill:none;stroke:url(#SVGID_86_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st87{fill:none;stroke:url(#SVGID_87_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st88{fill:none;stroke:url(#SVGID_88_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st89{fill:none;stroke:url(#SVGID_89_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st90{fill:none;stroke:url(#SVGID_90_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st91{fill:none;stroke:url(#SVGID_91_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st92{fill:none;stroke:url(#SVGID_92_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st93{fill:none;stroke:url(#SVGID_93_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st94{fill:none;stroke:url(#SVGID_94_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st95{fill:none;stroke:url(#SVGID_95_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st96{fill:none;stroke:url(#SVGID_96_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st97{fill:none;stroke:url(#SVGID_97_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st98{fill:none;stroke:url(#SVGID_98_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st99{fill:none;stroke:url(#SVGID_99_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st100{fill:none;stroke:url(#SVGID_100_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st101{fill:none;stroke:url(#SVGID_101_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st102{fill:none;stroke:url(#SVGID_102_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st103{fill:none;stroke:url(#SVGID_103_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st104{fill:none;stroke:url(#SVGID_104_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st105{fill:none;stroke:url(#SVGID_105_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st106{fill:none;stroke:url(#SVGID_106_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st107{fill:none;stroke:url(#SVGID_107_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st108{fill:none;stroke:url(#SVGID_108_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st109{fill:none;stroke:url(#SVGID_109_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st110{fill:none;stroke:url(#SVGID_110_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st111{fill:none;stroke:url(#SVGID_111_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st112{fill:none;stroke:url(#SVGID_112_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st113{fill:none;stroke:url(#SVGID_113_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st114{fill:none;stroke:url(#SVGID_114_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st115{clip-path:url(#SVGID_116_${key});}
	.st116{fill:none;stroke:url(#SVGID_117_${key});stroke-width:2;stroke-miterlimit:10;}
	.st117{fill:none;stroke:url(#SVGID_118_${key});stroke-width:2;stroke-miterlimit:10;}
	.st118{fill:none;stroke:url(#SVGID_119_${key});stroke-width:2;stroke-miterlimit:10;}
	.st119{fill:none;stroke:url(#SVGID_120_${key});stroke-width:2;stroke-miterlimit:10;}
	.st120{fill:none;stroke:url(#SVGID_121_${key});stroke-width:2;stroke-miterlimit:10;}
	.st121{fill:none;stroke:url(#SVGID_122_${key});stroke-width:2;stroke-miterlimit:10;}
	.st122{fill:none;stroke:url(#SVGID_123_${key});stroke-width:2;stroke-miterlimit:10;}
	.st123{fill:none;stroke:url(#SVGID_124_${key});stroke-width:2;stroke-miterlimit:10;}
	.st124{fill:none;stroke:url(#SVGID_125_${key});stroke-width:2;stroke-miterlimit:10;}
	.st125{fill:none;stroke:url(#SVGID_126_${key});stroke-width:2;stroke-miterlimit:10;}
	.st126{fill:url(#SVGID_127_${key});}
	.st127{opacity:0.6;clip-path:url(#SVGID_129_${key});fill:url(#SVGID_130_${key});}
	.st128{fill:none;stroke:url(#SVGID_131_${key});stroke-width:3;stroke-miterlimit:10;}
	.st129{fill:url(#SVGID_132_${key});}
	.st130{fill:url(#SVGID_133_${key});}
	.st131{fill:url(#SVGID_134_${key});}
	.st132{clip-path:url(#SVGID_136_${key});}
	.st133{fill:none;stroke:url(#SVGID_137_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st134{fill:none;stroke:url(#SVGID_138_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st135{fill:none;stroke:url(#SVGID_139_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st136{fill:none;stroke:url(#SVGID_140_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st137{fill:none;stroke:url(#SVGID_141_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st138{fill:none;stroke:url(#SVGID_142_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st139{fill:none;stroke:url(#SVGID_143_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st140{fill:none;stroke:url(#SVGID_144_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st141{fill:none;stroke:url(#SVGID_145_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st142{fill:none;stroke:url(#SVGID_146_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st143{fill:none;stroke:url(#SVGID_147_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st144{fill:none;stroke:url(#SVGID_148_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st145{fill:none;stroke:url(#SVGID_149_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st146{fill:none;stroke:url(#SVGID_150_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st147{fill:none;stroke:url(#SVGID_151_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st148{fill:none;stroke:url(#SVGID_152_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st149{fill:none;stroke:url(#SVGID_153_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st150{fill:none;stroke:url(#SVGID_154_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st151{fill:none;stroke:url(#SVGID_155_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st152{fill:none;stroke:url(#SVGID_156_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st153{fill:none;stroke:url(#SVGID_157_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st154{fill:none;stroke:url(#SVGID_158_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st155{fill:none;stroke:url(#SVGID_159_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st156{fill:none;stroke:url(#SVGID_160_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st157{fill:none;stroke:url(#SVGID_161_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st158{fill:none;stroke:url(#SVGID_162_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st159{fill:none;stroke:url(#SVGID_163_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st160{fill:none;stroke:url(#SVGID_164_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st161{fill:none;stroke:url(#SVGID_165_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st162{fill:none;stroke:url(#SVGID_166_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st163{fill:none;stroke:url(#SVGID_167_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st164{fill:none;stroke:url(#SVGID_168_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st165{fill:none;stroke:url(#SVGID_169_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st166{fill:none;stroke:url(#SVGID_170_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st167{fill:none;stroke:url(#SVGID_171_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st168{fill:none;stroke:url(#SVGID_172_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st169{fill:none;stroke:url(#SVGID_173_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st170{fill:none;stroke:url(#SVGID_174_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st171{fill:none;stroke:url(#SVGID_175_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st172{fill:none;stroke:url(#SVGID_176_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st173{fill:none;stroke:url(#SVGID_177_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st174{fill:none;stroke:url(#SVGID_178_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st175{clip-path:url(#SVGID_180_${key});}
	.st176{fill:none;stroke:url(#SVGID_181_${key});stroke-width:2;stroke-miterlimit:10;}
	.st177{fill:none;stroke:url(#SVGID_182_${key});stroke-width:2;stroke-miterlimit:10;}
	.st178{fill:none;stroke:url(#SVGID_183_${key});stroke-width:2;stroke-miterlimit:10;}
	.st179{fill:none;stroke:url(#SVGID_184_${key});stroke-width:2;stroke-miterlimit:10;}
	.st180{fill:none;stroke:url(#SVGID_185_${key});stroke-width:2;stroke-miterlimit:10;}
	.st181{fill:none;stroke:url(#SVGID_186_${key});stroke-width:2;stroke-miterlimit:10;}
	.st182{fill:none;stroke:url(#SVGID_187_${key});stroke-width:2;stroke-miterlimit:10;}
	.st183{fill:none;stroke:url(#SVGID_188_${key});stroke-width:2;stroke-miterlimit:10;}
	.st184{fill:none;stroke:url(#SVGID_189_${key});stroke-width:2;stroke-miterlimit:10;}
	.st185{fill:none;stroke:url(#SVGID_190_${key});stroke-width:2;stroke-miterlimit:10;}
	.st186{fill:none;stroke:url(#SVGID_191_${key});stroke-width:2;stroke-miterlimit:10;}
	.st187{opacity:0.7;}
	.st188{opacity:0.12;clip-path:url(#SVGID_193_${key});}
	.st189{fill:none;stroke:#B2B2B2;stroke-width:2;stroke-miterlimit:10;}
	.st190{fill:url(#SVGID_194_${key});}
	.st191{fill:none;stroke:#1D1D1B;stroke-width:3;stroke-miterlimit:10;}
	.st192{fill:url(#SVGID_195_${key});}
	.st193{opacity:0.07;clip-path:url(#SVGID_197_${key});}
	.st194{opacity:0.4;clip-path:url(#SVGID_197_${key});fill:url(#SVGID_198_${key});}
	.st195{fill:none;stroke:url(#SVGID_199_${key});stroke-width:3;stroke-miterlimit:10;}
	.st196{fill:url(#SVGID_200_${key});}
	.st197{fill:url(#SVGID_201_${key});}
	.st198{fill:url(#SVGID_202_${key});}
	.st199{fill:url(#SVGID_203_${key});}
	.st200{clip-path:url(#SVGID_205_${key});}
	.st201{fill:none;stroke:url(#SVGID_206_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st202{fill:none;stroke:url(#SVGID_207_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st203{fill:none;stroke:url(#SVGID_208_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st204{fill:none;stroke:url(#SVGID_209_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st205{fill:none;stroke:url(#SVGID_210_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st206{fill:none;stroke:url(#SVGID_211_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st207{fill:none;stroke:url(#SVGID_212_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st208{fill:none;stroke:url(#SVGID_213_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st209{fill:none;stroke:url(#SVGID_214_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st210{fill:none;stroke:url(#SVGID_215_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st211{fill:none;stroke:url(#SVGID_216_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st212{fill:none;stroke:url(#SVGID_217_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st213{fill:none;stroke:url(#SVGID_218_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st214{fill:none;stroke:url(#SVGID_219_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st215{fill:none;stroke:url(#SVGID_220_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st216{fill:none;stroke:url(#SVGID_221_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st217{fill:none;stroke:url(#SVGID_222_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st218{fill:none;stroke:url(#SVGID_223_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st219{fill:none;stroke:url(#SVGID_224_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st220{fill:none;stroke:url(#SVGID_225_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st221{fill:none;stroke:url(#SVGID_226_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st222{fill:none;stroke:url(#SVGID_227_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st223{fill:none;stroke:url(#SVGID_228_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st224{fill:none;stroke:url(#SVGID_229_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st225{fill:none;stroke:url(#SVGID_230_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st226{fill:none;stroke:url(#SVGID_231_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st227{fill:none;stroke:url(#SVGID_232_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st228{fill:none;stroke:url(#SVGID_233_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st229{fill:none;stroke:url(#SVGID_234_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st230{fill:none;stroke:url(#SVGID_235_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st231{fill:none;stroke:url(#SVGID_236_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st232{fill:none;stroke:url(#SVGID_237_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st233{fill:none;stroke:url(#SVGID_238_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st234{fill:none;stroke:url(#SVGID_239_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st235{fill:none;stroke:url(#SVGID_240_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st236{fill:none;stroke:url(#SVGID_241_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st237{fill:none;stroke:url(#SVGID_242_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st238{fill:none;stroke:url(#SVGID_243_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st239{fill:none;stroke:url(#SVGID_244_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st240{fill:none;stroke:url(#SVGID_245_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st241{fill:none;stroke:url(#SVGID_246_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st242{fill:none;stroke:url(#SVGID_247_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st243{fill:none;stroke:url(#SVGID_248_${key});stroke-width:3;stroke-miterlimit:10;}
	.st244{fill:none;stroke:url(#SVGID_249_${key});stroke-width:3;stroke-miterlimit:10;}
	.st245{fill:none;stroke:url(#SVGID_250_${key});stroke-width:3;stroke-miterlimit:10;}
	.st246{fill:none;stroke:url(#SVGID_251_${key});stroke-width:3;stroke-miterlimit:10;}
	.st247{fill:none;stroke:url(#SVGID_252_${key});stroke-width:3;stroke-miterlimit:10;}
	.st248{fill:none;stroke:url(#SVGID_253_${key});stroke-width:3;stroke-miterlimit:10;}
	.st249{fill:none;stroke:url(#SVGID_254_${key});stroke-width:3;stroke-miterlimit:10;}
	.st250{fill:url(#SVGID_255_${key});}
	.st251{fill:url(#SVGID_256_${key});}
	.st252{fill:url(#SVGID_257_${key});}
	.st253{clip-path:url(#SVGID_259_${key});}
	.st254{fill:none;stroke:url(#SVGID_260_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st255{fill:none;stroke:url(#SVGID_261_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st256{fill:none;stroke:url(#SVGID_262_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st257{fill:none;stroke:url(#SVGID_263_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st258{fill:none;stroke:url(#SVGID_264_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st259{fill:none;stroke:url(#SVGID_265_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st260{fill:none;stroke:url(#SVGID_266_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st261{fill:none;stroke:url(#SVGID_267_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st262{fill:none;stroke:url(#SVGID_268_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st263{fill:none;stroke:url(#SVGID_269_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st264{fill:none;stroke:url(#SVGID_270_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st265{fill:none;stroke:url(#SVGID_271_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st266{fill:none;stroke:url(#SVGID_272_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st267{fill:none;stroke:url(#SVGID_273_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st268{fill:none;stroke:url(#SVGID_274_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st269{fill:none;stroke:url(#SVGID_275_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st270{fill:none;stroke:url(#SVGID_276_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st271{fill:none;stroke:url(#SVGID_277_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st272{fill:none;stroke:url(#SVGID_278_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st273{fill:none;stroke:url(#SVGID_279_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st274{fill:none;stroke:url(#SVGID_280_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st275{fill:none;stroke:url(#SVGID_281_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st276{fill:none;stroke:url(#SVGID_282_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st277{fill:none;stroke:url(#SVGID_283_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st278{fill:none;stroke:url(#SVGID_284_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st279{fill:none;stroke:url(#SVGID_285_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st280{fill:none;stroke:url(#SVGID_286_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st281{fill:none;stroke:url(#SVGID_287_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st282{fill:none;stroke:url(#SVGID_288_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st283{fill:none;stroke:url(#SVGID_289_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st284{fill:none;stroke:url(#SVGID_290_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st285{fill:none;stroke:url(#SVGID_291_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st286{fill:none;stroke:url(#SVGID_292_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st287{fill:none;stroke:url(#SVGID_293_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st288{fill:none;stroke:url(#SVGID_294_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st289{fill:none;stroke:url(#SVGID_295_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st290{fill:none;stroke:url(#SVGID_296_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st291{fill:none;stroke:url(#SVGID_297_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st292{fill:none;stroke:url(#SVGID_298_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st293{fill:none;stroke:url(#SVGID_299_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st294{fill:none;stroke:url(#SVGID_300_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st295{fill:none;stroke:url(#SVGID_301_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st296{fill:none;stroke:url(#SVGID_302_${key});stroke-width:3;stroke-miterlimit:10;}
	.st297{fill:none;stroke:url(#SVGID_303_${key});stroke-width:3;stroke-miterlimit:10;}
	.st298{fill:none;stroke:url(#SVGID_304_${key});stroke-width:3;stroke-miterlimit:10;}
	.st299{fill:none;stroke:url(#SVGID_305_${key});stroke-width:3;stroke-miterlimit:10;}
	.st300{fill:none;stroke:url(#SVGID_306_${key});stroke-width:3;stroke-miterlimit:10;}
	.st301{fill:none;stroke:url(#SVGID_307_${key});stroke-width:3;stroke-miterlimit:10;}
	.st302{fill:none;stroke:url(#SVGID_308_${key});stroke-width:3;stroke-miterlimit:10;}
	.st303{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
	.st304{fill:url(#SVGID_309_${key});}
	.st305{fill:none;stroke:url(#SVGID_310_${key});stroke-width:3;stroke-miterlimit:10;}
	.st306{fill:none;stroke:#B2B2B2;stroke-width:3;stroke-miterlimit:10;}
	.st307{clip-path:url(#SVGID_312_${key});}
	.st308{opacity:0.51;fill:none;stroke:url(#SVGID_313_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st309{opacity:0.51;fill:none;stroke:url(#SVGID_314_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st310{opacity:0.82;fill:none;stroke:url(#SVGID_315_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st311{opacity:0.51;fill:none;stroke:url(#SVGID_316_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st312{opacity:0.51;fill:none;stroke:url(#SVGID_317_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st313{opacity:0.51;fill:none;stroke:url(#SVGID_318_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st314{opacity:0.82;fill:none;stroke:url(#SVGID_319_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st315{opacity:0.51;fill:none;stroke:url(#SVGID_320_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st316{fill:url(#SVGID_321_${key});}
	.st317{fill:none;stroke:url(#SVGID_322_${key});stroke-width:3;stroke-miterlimit:10;}
	.st318{clip-path:url(#SVGID_324_${key});}
	.st319{opacity:0.51;fill:none;stroke:url(#SVGID_325_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st320{opacity:0.51;fill:none;stroke:url(#SVGID_326_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st321{opacity:0.82;fill:none;stroke:url(#SVGID_327_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st322{opacity:0.51;fill:none;stroke:url(#SVGID_328_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st323{opacity:0.51;fill:none;stroke:url(#SVGID_329_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st324{opacity:0.51;fill:none;stroke:url(#SVGID_330_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st325{opacity:0.82;fill:none;stroke:url(#SVGID_331_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st326{opacity:0.51;fill:none;stroke:url(#SVGID_332_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st327{fill:url(#SVGID_333_${key});}
	.st328{fill:none;stroke:url(#SVGID_334_${key});stroke-width:2;stroke-miterlimit:10;}
	.st329{fill:url(#SVGID_335_${key});}
	.st330{opacity:0.25;}
	.st331{fill:url(#SVGID_336_${key});}
	.st332{fill:url(#SVGID_337_${key});}
	.st333{fill:url(#SVGID_338_${key});}
	.st334{fill:url(#SVGID_339_${key});}
	.st335{fill:url(#SVGID_340_${key});}
	.st336{fill:url(#SVGID_341_${key});}
	.st337{fill:url(#SVGID_342_${key});}
	.st338{fill:url(#SVGID_343_${key});}
	.st339{fill:url(#SVGID_344_${key});}
	.st340{fill:url(#SVGID_345_${key});}
	.st341{fill:url(#SVGID_346_${key});}
	.st342{fill:url(#SVGID_347_${key});}
	.st343{fill:url(#SVGID_348_${key});}
	.st344{fill:url(#SVGID_349_${key});}
	.st345{fill:url(#SVGID_350_${key});}
	.st346{fill:url(#SVGID_351_${key});}
	.st347{fill:url(#SVGID_352_${key});}
	.st348{fill:url(#SVGID_353_${key});}
	.st349{fill:url(#SVGID_354_${key});}
	.st350{fill:url(#SVGID_355_${key});}
	.st351{fill:url(#SVGID_356_${key});}
	.st352{fill:url(#SVGID_357_${key});}
	.st353{fill:url(#SVGID_358_${key});}
	.st354{fill:url(#SVGID_359_${key});}
	.st355{fill:url(#SVGID_360_${key});}
	.st356{fill:url(#SVGID_361_${key});}
	.st357{fill:url(#SVGID_362_${key});}
	.st358{fill:url(#SVGID_363_${key});}
	.st359{fill:url(#SVGID_364_${key});}
	.st360{fill:url(#SVGID_365_${key});}
	.st361{fill:url(#SVGID_366_${key});}
	.st362{fill:url(#SVGID_367_${key});}
	.st363{fill:url(#SVGID_368_${key});}
	.st364{fill:url(#SVGID_369_${key});}
	.st365{fill:url(#SVGID_370_${key});}
	.st366{fill:url(#SVGID_371_${key});}
	.st367{fill:url(#SVGID_372_${key});}
	.st368{fill:url(#SVGID_373_${key});}
	.st369{fill:url(#SVGID_374_${key});}
	.st370{fill:url(#SVGID_375_${key});}
	.st371{fill:#071D50;stroke:url(#SVGID_376_${key});stroke-width:2;stroke-miterlimit:10;}
	.st372{opacity:0.12;clip-path:url(#SVGID_378_${key});}
	.st373{fill:#071D50;}
	.st374{fill:url(#SVGID_379_${key});}
	.st375{fill:none;stroke:url(#SVGID_380_${key});stroke-width:3;stroke-miterlimit:10;}
	.st376{fill:url(#SVGID_381_${key});}
	.st377{fill:url(#SVGID_382_${key});}
	.st378{fill:url(#SVGID_383_${key});}
	.st379{fill:url(#SVGID_384_${key});}
	.st380{fill:url(#SVGID_385_${key});}
	.st381{fill:url(#SVGID_386_${key});}
	.st382{fill:url(#SVGID_387_${key});}
	.st383{fill:url(#SVGID_388_${key});}
	.st384{fill:url(#SVGID_389_${key});}
	.st385{fill:url(#SVGID_390_${key});}
	.st386{fill:url(#SVGID_391_${key});}
	.st387{fill:url(#SVGID_392_${key});}
	.st388{fill:url(#SVGID_393_${key});}
	.st389{fill:url(#SVGID_394_${key});}
	.st390{fill:url(#SVGID_395_${key});}
	.st391{fill:url(#SVGID_396_${key});}
	.st392{fill:url(#SVGID_397_${key});}
	.st393{fill:url(#SVGID_398_${key});}
	.st394{fill:url(#SVGID_399_${key});}
	.st395{opacity:0.6;clip-path:url(#SVGID_401_${key});fill:url(#SVGID_402_${key});}
	.st396{fill:none;stroke:url(#SVGID_403_${key});stroke-width:3;stroke-miterlimit:10;}
	.st397{fill:url(#SVGID_404_${key});}
	.st398{opacity:0.07;clip-path:url(#SVGID_406_${key});}
	.st399{opacity:0.4;clip-path:url(#SVGID_406_${key});fill:url(#SVGID_407_${key});}
	.st400{fill:none;stroke:url(#SVGID_408_${key});stroke-width:3;stroke-miterlimit:10;}
	.st401{fill:url(#SVGID_409_${key});}
	.st402{fill:url(#SVGID_410_${key});}
	.st403{fill:url(#SVGID_411_${key});}
	.st404{clip-path:url(#SVGID_413_${key});}
	.st405{fill:none;stroke:url(#SVGID_414_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st406{fill:none;stroke:url(#SVGID_415_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st407{fill:none;stroke:url(#SVGID_416_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st408{fill:none;stroke:url(#SVGID_417_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st409{fill:none;stroke:url(#SVGID_418_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st410{fill:none;stroke:url(#SVGID_419_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st411{fill:none;stroke:url(#SVGID_420_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st412{fill:none;stroke:url(#SVGID_421_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st413{fill:none;stroke:url(#SVGID_422_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st414{fill:none;stroke:url(#SVGID_423_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st415{fill:none;stroke:url(#SVGID_424_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st416{fill:none;stroke:url(#SVGID_425_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st417{fill:none;stroke:url(#SVGID_426_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st418{fill:none;stroke:url(#SVGID_427_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st419{fill:none;stroke:url(#SVGID_428_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st420{fill:none;stroke:url(#SVGID_429_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st421{fill:none;stroke:url(#SVGID_430_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st422{fill:none;stroke:url(#SVGID_431_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st423{fill:none;stroke:url(#SVGID_432_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st424{fill:none;stroke:url(#SVGID_433_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st425{fill:none;stroke:url(#SVGID_434_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st426{fill:none;stroke:url(#SVGID_435_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st427{fill:none;stroke:url(#SVGID_436_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st428{fill:none;stroke:url(#SVGID_437_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st429{fill:none;stroke:url(#SVGID_438_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st430{fill:none;stroke:url(#SVGID_439_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st431{fill:none;stroke:url(#SVGID_440_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st432{fill:none;stroke:url(#SVGID_441_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st433{fill:none;stroke:url(#SVGID_442_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st434{fill:none;stroke:url(#SVGID_443_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st435{fill:none;stroke:url(#SVGID_444_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st436{fill:none;stroke:url(#SVGID_445_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st437{fill:none;stroke:url(#SVGID_446_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st438{fill:none;stroke:url(#SVGID_447_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st439{fill:none;stroke:url(#SVGID_448_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st440{fill:none;stroke:url(#SVGID_449_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st441{fill:none;stroke:url(#SVGID_450_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st442{fill:none;stroke:url(#SVGID_451_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st443{fill:none;stroke:url(#SVGID_452_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st444{fill:none;stroke:url(#SVGID_453_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st445{fill:none;stroke:url(#SVGID_454_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st446{fill:none;stroke:url(#SVGID_455_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st447{fill:none;}
	.st449{font-size:90px;}
	.st450{letter-spacing:-9;}
	.st452{font-size:25px;}
	.st453{letter-spacing:2;}
	.st454{clip-path:url(#SVGID_457_${key});}
	.st455{fill:none;stroke:url(#SVGID_458_${key});stroke-width:2;stroke-miterlimit:10;}
	.st456{fill:none;stroke:url(#SVGID_459_${key});stroke-width:2;stroke-miterlimit:10;}
	.st457{fill:none;stroke:url(#SVGID_460_${key});stroke-width:2;stroke-miterlimit:10;}
	.st458{fill:none;stroke:url(#SVGID_461_${key});stroke-width:2;stroke-miterlimit:10;}
	.st459{fill:none;stroke:url(#SVGID_462_${key});stroke-width:2;stroke-miterlimit:10;}
	.st460{fill:none;stroke:url(#SVGID_463_${key});stroke-width:2;stroke-miterlimit:10;}
	.st461{fill:none;stroke:url(#SVGID_464_${key});stroke-width:2;stroke-miterlimit:10;}
	.st462{fill:none;stroke:url(#SVGID_465_${key});stroke-width:2;stroke-miterlimit:10;}
	.st463{fill:none;stroke:url(#SVGID_466_${key});stroke-width:2;stroke-miterlimit:10;}
	.st464{fill:none;stroke:url(#SVGID_467_${key});stroke-width:2;stroke-miterlimit:10;}
	.st465{fill:url(#SVGID_468_${key});}
	.st466{fill:url(#SVGID_469_${key});}
	.st467{fill:url(#SVGID_470_${key});}
	.st468{clip-path:url(#SVGID_472_${key});}
	.st469{fill:url(#SVGID_473_${key});}
	.st470{fill:url(#SVGID_474_${key});}
	.st471{fill:url(#SVGID_475_${key});}
	.st472{fill:url(#SVGID_476_${key});}
	.st473{fill:url(#SVGID_477_${key});}
	.st474{fill:url(#SVGID_478_${key});}
	.st475{fill:url(#SVGID_479_${key});}
	.st476{fill:url(#SVGID_480_${key});}
	.st477{fill:url(#SVGID_481_${key});}
	.st478{fill:url(#SVGID_482_${key});}
	.st479{fill:url(#SVGID_483_${key});}
	.st480{fill:url(#SVGID_484_${key});}
	.st481{fill:url(#SVGID_485_${key});}
	.st482{fill:url(#SVGID_486_${key});}
	.st483{fill:url(#SVGID_487_${key});}
	.st484{fill:url(#SVGID_488_${key});}
	.st485{fill:url(#SVGID_489_${key});}
	.st486{fill:url(#SVGID_490_${key});}
	.st487{fill:url(#SVGID_491_${key});}
	.st488{fill:url(#SVGID_492_${key});}
	.st489{fill:url(#SVGID_493_${key});}
	.st490{fill:url(#SVGID_494_${key});}
	.st491{fill:url(#SVGID_495_${key});}
	.st492{fill:url(#SVGID_496_${key});}
	.st493{fill:url(#SVGID_497_${key});}
	.st494{fill:url(#SVGID_498_${key});}
	.st495{fill:url(#SVGID_499_${key});}
	.st496{fill:url(#SVGID_500_${key});}
	.st497{fill:url(#SVGID_501_${key});}
	.st498{fill:url(#SVGID_502_${key});}
	.st499{fill:url(#SVGID_503_${key});}
	.st500{fill:url(#SVGID_504_${key});}
	.st501{fill:url(#SVGID_505_${key});}
	.st502{fill:url(#SVGID_506_${key});}
	.st503{fill:url(#SVGID_507_${key});}
	.st504{fill:url(#SVGID_508_${key});}
	.st505{fill:url(#SVGID_509_${key});}
	.st506{fill:url(#SVGID_510_${key});}
	.st507{fill:url(#SVGID_511_${key});}
	.st508{fill:url(#SVGID_512_${key});}
	.st509{fill:url(#SVGID_513_${key});}
	.st510{fill:url(#SVGID_514_${key});}
	.st511{clip-path:url(#SVGID_516_${key});}
	.st512{fill:url(#SVGID_517_${key});}
	.st513{fill:url(#SVGID_518_${key});}
	.st514{fill:url(#SVGID_519_${key});}
	.st515{fill:url(#SVGID_520_${key});}
	.st516{fill:url(#SVGID_521_${key});}
	.st517{fill:url(#SVGID_522_${key});}
	.st518{fill:url(#SVGID_523_${key});}
	.st519{fill:url(#SVGID_524_${key});}
	.st520{fill:url(#SVGID_525_${key});}
	.st521{fill:url(#SVGID_526_${key});}
	.st522{fill:url(#SVGID_527_${key});}
	.st523{opacity:0.12;clip-path:url(#SVGID_529_${key});}
	.st524{fill:#B2B2B2;}
	.st525{fill:url(#SVGID_530_${key});}
	.st526{fill:url(#SVGID_531_${key});}
	.st527{fill:url(#SVGID_532_${key});}
	.st528{fill:url(#SVGID_533_${key});}
	.st529{clip-path:url(#SVGID_535_${key});}
	.st530{fill:none;stroke:url(#SVGID_536_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st531{fill:none;stroke:url(#SVGID_537_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st532{fill:none;stroke:url(#SVGID_538_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st533{fill:none;stroke:url(#SVGID_539_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st534{fill:none;stroke:url(#SVGID_540_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st535{fill:none;stroke:url(#SVGID_541_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st536{fill:none;stroke:url(#SVGID_542_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st537{fill:none;stroke:url(#SVGID_543_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st538{fill:none;stroke:url(#SVGID_544_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st539{fill:none;stroke:url(#SVGID_545_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st540{fill:none;stroke:url(#SVGID_546_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st541{fill:none;stroke:url(#SVGID_547_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st542{fill:none;stroke:url(#SVGID_548_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st543{fill:none;stroke:url(#SVGID_549_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st544{fill:none;stroke:url(#SVGID_550_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st545{fill:none;stroke:url(#SVGID_551_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st546{fill:none;stroke:url(#SVGID_552_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st547{fill:none;stroke:url(#SVGID_553_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st548{fill:none;stroke:url(#SVGID_554_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st549{fill:none;stroke:url(#SVGID_555_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st550{fill:none;stroke:url(#SVGID_556_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st551{fill:none;stroke:url(#SVGID_557_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st552{fill:none;stroke:url(#SVGID_558_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st553{fill:none;stroke:url(#SVGID_559_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st554{fill:none;stroke:url(#SVGID_560_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st555{fill:none;stroke:url(#SVGID_561_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st556{fill:none;stroke:url(#SVGID_562_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st557{fill:none;stroke:url(#SVGID_563_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st558{fill:none;stroke:url(#SVGID_564_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st559{fill:none;stroke:url(#SVGID_565_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st560{fill:none;stroke:url(#SVGID_566_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st561{fill:none;stroke:url(#SVGID_567_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st562{fill:none;stroke:url(#SVGID_568_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st563{fill:none;stroke:url(#SVGID_569_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st564{fill:none;stroke:url(#SVGID_570_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st565{fill:none;stroke:url(#SVGID_571_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st566{fill:none;stroke:url(#SVGID_572_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st567{fill:none;stroke:url(#SVGID_573_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st568{fill:none;stroke:url(#SVGID_574_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st569{fill:none;stroke:url(#SVGID_575_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st570{fill:none;stroke:url(#SVGID_576_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st571{fill:none;stroke:url(#SVGID_577_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st572{fill:url(#SVGID_578_${key});}
	.st573{fill:url(#SVGID_579_${key});}
	.st574{fill:url(#SVGID_580_${key});}
	.st575{fill:url(#SVGID_581_${key});}
	.st576{fill:url(#SVGID_582_${key});}
	.st577{fill:url(#SVGID_583_${key});}
	.st578{fill:url(#SVGID_584_${key});}
	.st579{fill:url(#SVGID_585_${key});}
	.st580{fill:url(#SVGID_586_${key});}
	.st581{fill:url(#SVGID_587_${key});}
	.st582{clip-path:url(#SVGID_589_${key});}
	.st583{fill:none;stroke:url(#SVGID_590_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st584{fill:none;stroke:url(#SVGID_591_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st585{fill:none;stroke:url(#SVGID_592_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st586{fill:none;stroke:url(#SVGID_593_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st587{fill:none;stroke:url(#SVGID_594_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st588{fill:none;stroke:url(#SVGID_595_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st589{fill:none;stroke:url(#SVGID_596_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st590{fill:none;stroke:url(#SVGID_597_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st591{fill:none;stroke:url(#SVGID_598_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st592{fill:none;stroke:url(#SVGID_599_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st593{fill:none;stroke:url(#SVGID_600_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st594{fill:none;stroke:url(#SVGID_601_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st595{fill:none;stroke:url(#SVGID_602_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st596{fill:none;stroke:url(#SVGID_603_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st597{fill:none;stroke:url(#SVGID_604_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st598{fill:none;stroke:url(#SVGID_605_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st599{fill:none;stroke:url(#SVGID_606_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st600{fill:none;stroke:url(#SVGID_607_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st601{fill:none;stroke:url(#SVGID_608_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st602{fill:none;stroke:url(#SVGID_609_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st603{fill:none;stroke:url(#SVGID_610_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st604{fill:none;stroke:url(#SVGID_611_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st605{fill:none;stroke:url(#SVGID_612_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st606{fill:none;stroke:url(#SVGID_613_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st607{fill:none;stroke:url(#SVGID_614_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st608{fill:none;stroke:url(#SVGID_615_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st609{fill:none;stroke:url(#SVGID_616_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st610{fill:none;stroke:url(#SVGID_617_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st611{fill:none;stroke:url(#SVGID_618_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st612{fill:none;stroke:url(#SVGID_619_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st613{fill:none;stroke:url(#SVGID_620_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st614{fill:none;stroke:url(#SVGID_621_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st615{fill:none;stroke:url(#SVGID_622_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st616{fill:none;stroke:url(#SVGID_623_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st617{fill:none;stroke:url(#SVGID_624_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st618{fill:none;stroke:url(#SVGID_625_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st619{fill:none;stroke:url(#SVGID_626_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st620{fill:none;stroke:url(#SVGID_627_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st621{fill:none;stroke:url(#SVGID_628_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st622{fill:none;stroke:url(#SVGID_629_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st623{fill:none;stroke:url(#SVGID_630_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st624{fill:none;stroke:url(#SVGID_631_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st625{fill:none;stroke:url(#SVGID_632_${key});stroke-width:3;stroke-miterlimit:10;}
	.st626{fill:none;stroke:url(#SVGID_633_${key});stroke-width:3;stroke-miterlimit:10;}
	.st627{fill:none;stroke:url(#SVGID_634_${key});stroke-width:3;stroke-miterlimit:10;}
	.st628{fill:none;stroke:url(#SVGID_635_${key});stroke-width:3;stroke-miterlimit:10;}
	.st629{fill:none;stroke:url(#SVGID_636_${key});stroke-width:3;stroke-miterlimit:10;}
	.st630{fill:none;stroke:url(#SVGID_637_${key});stroke-width:3;stroke-miterlimit:10;}
	.st631{fill:none;stroke:url(#SVGID_638_${key});stroke-width:3;stroke-miterlimit:10;}
	.st632{fill:url(#SVGID_639_${key});}
	.st633{fill:url(#SVGID_640_${key});}
	.st634{clip-path:url(#SVGID_642_${key});}
	.st635{opacity:0.51;fill:none;stroke:url(#SVGID_643_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st636{opacity:0.51;fill:none;stroke:url(#SVGID_644_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st637{opacity:0.82;fill:none;stroke:url(#SVGID_645_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st638{opacity:0.51;fill:none;stroke:url(#SVGID_646_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st639{opacity:0.51;fill:none;stroke:url(#SVGID_647_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st640{opacity:0.51;fill:none;stroke:url(#SVGID_648_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st641{opacity:0.82;fill:none;stroke:url(#SVGID_649_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st642{opacity:0.51;fill:none;stroke:url(#SVGID_650_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st643{fill:url(#SVGID_651_${key});}
	.st644{fill:url(#SVGID_652_${key});}
	.st645{clip-path:url(#SVGID_654_${key});}
	.st646{opacity:0.51;fill:none;stroke:url(#SVGID_655_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st647{opacity:0.51;fill:none;stroke:url(#SVGID_656_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st648{opacity:0.82;fill:none;stroke:url(#SVGID_657_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st649{opacity:0.51;fill:none;stroke:url(#SVGID_658_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st650{opacity:0.51;fill:none;stroke:url(#SVGID_659_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st651{opacity:0.51;fill:none;stroke:url(#SVGID_660_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st652{opacity:0.82;fill:none;stroke:url(#SVGID_661_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st653{opacity:0.51;fill:none;stroke:url(#SVGID_662_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st654{fill:url(#SVGID_663_${key});}
	.st655{fill:none;stroke:url(#SVGID_664_${key});stroke-width:2;stroke-miterlimit:10;}
	.st656{fill:url(#SVGID_665_${key});}
	.st657{fill:url(#SVGID_666_${key});}
	.st658{fill:url(#SVGID_667_${key});}
	.st659{fill:url(#SVGID_668_${key});}
	.st660{fill:url(#SVGID_669_${key});}
	.st661{fill:url(#SVGID_670_${key});}
	.st662{fill:url(#SVGID_671_${key});}
	.st663{fill:url(#SVGID_672_${key});}
	.st664{fill:url(#SVGID_673_${key});}
	.st665{fill:url(#SVGID_674_${key});}
	.st666{fill:url(#SVGID_675_${key});}
	.st667{fill:url(#SVGID_676_${key});}
	.st668{fill:url(#SVGID_677_${key});}
	.st669{fill:url(#SVGID_678_${key});}
	.st670{fill:url(#SVGID_679_${key});}
	.st671{fill:url(#SVGID_680_${key});}
	.st672{fill:url(#SVGID_681_${key});}
	.st673{fill:url(#SVGID_682_${key});}
	.st674{fill:url(#SVGID_683_${key});}
	.st675{fill:url(#SVGID_684_${key});}
	.st676{fill:url(#SVGID_685_${key});}
	.st677{fill:url(#SVGID_686_${key});}
	.st678{fill:url(#SVGID_687_${key});}
	.st679{fill:url(#SVGID_688_${key});}
	.st680{fill:url(#SVGID_689_${key});}
	.st681{fill:url(#SVGID_690_${key});}
	.st682{fill:url(#SVGID_691_${key});}
	.st683{fill:url(#SVGID_692_${key});}
	.st684{fill:url(#SVGID_693_${key});}
	.st685{fill:url(#SVGID_694_${key});}
	.st686{fill:url(#SVGID_695_${key});}
	.st687{fill:url(#SVGID_696_${key});}
	.st688{fill:url(#SVGID_697_${key});}
	.st689{fill:url(#SVGID_698_${key});}
	.st690{fill:url(#SVGID_699_${key});}
	.st691{fill:url(#SVGID_700_${key});}
	.st692{fill:url(#SVGID_701_${key});}
	.st693{fill:url(#SVGID_702_${key});}
	.st694{fill:url(#SVGID_703_${key});}
	.st695{fill:url(#SVGID_704_${key});}
	.st696{fill:none;stroke:url(#SVGID_705_${key});stroke-width:3;stroke-miterlimit:10;}
	.st697{fill:url(#SVGID_706_${key});}
	.st698{fill:url(#SVGID_707_${key});}
	.st699{fill:url(#SVGID_708_${key});}
	.st700{fill:url(#SVGID_709_${key});}
	.st701{fill:url(#SVGID_710_${key});}
	.st702{fill:url(#SVGID_711_${key});}
	.st703{fill:url(#SVGID_712_${key});}
	.st704{fill:url(#SVGID_713_${key});}
	.st705{fill:url(#SVGID_714_${key});}
	.st706{fill:url(#SVGID_715_${key});}
	.st707{fill:url(#SVGID_716_${key});}
	.st708{fill:url(#SVGID_717_${key});}
	.st709{fill:url(#SVGID_718_${key});}
	.st710{fill:url(#SVGID_719_${key});}
	.st711{fill:url(#SVGID_720_${key});}
	.st712{fill:url(#SVGID_721_${key});}
	.st713{fill:url(#SVGID_722_${key});}
	.st714{fill:url(#SVGID_723_${key});}
	.st715{fill:none;stroke:url(#SVGID_724_${key});stroke-width:3;stroke-miterlimit:10;}
	.st716{fill:url(#SVGID_725_${key});}
	.st717{fill:url(#SVGID_726_${key});}
	.st718{fill:url(#SVGID_727_${key});}
	.st719{fill:url(#SVGID_728_${key});}
	.st720{fill:url(#SVGID_729_${key});}
	.st721{fill:url(#SVGID_730_${key});}
	.st722{fill:url(#SVGID_731_${key});}
	.st723{fill:url(#SVGID_732_${key});}
	.st724{fill:url(#SVGID_733_${key});}
	.st725{fill:url(#SVGID_734_${key});}
	.st726{fill:url(#SVGID_735_${key});}
	.st727{fill:url(#SVGID_736_${key});}
	.st728{fill:url(#SVGID_737_${key});}
	.st729{fill:url(#SVGID_738_${key});}
	.st730{fill:url(#SVGID_739_${key});}
	.st731{fill:url(#SVGID_740_${key});}
	.st732{fill:url(#SVGID_741_${key});}
	.st733{fill:url(#SVGID_742_${key});}
	.st734{fill:url(#SVGID_743_${key});}
	.st735{fill:url(#SVGID_744_${key});}
	.st736{opacity:0.6;clip-path:url(#SVGID_746_${key});fill:url(#SVGID_747_${key});}
	.st737{fill:none;stroke:url(#SVGID_748_${key});stroke-width:3;stroke-miterlimit:10;}
	.st738{fill:url(#SVGID_749_${key});}
	.st739{opacity:0.07;clip-path:url(#SVGID_751_${key});}
	.st740{opacity:0.4;clip-path:url(#SVGID_751_${key});fill:url(#SVGID_752_${key});}
	.st741{fill:url(#SVGID_753_${key});}
	.st742{fill:url(#SVGID_754_${key});}
	.st743{fill:url(#SVGID_755_${key});}
	.st744{fill:url(#SVGID_756_${key});}
	.st745{clip-path:url(#SVGID_758_${key});}
	.st746{fill:none;stroke:url(#SVGID_759_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st747{fill:none;stroke:url(#SVGID_760_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st748{fill:none;stroke:url(#SVGID_761_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st749{fill:none;stroke:url(#SVGID_762_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st750{fill:none;stroke:url(#SVGID_763_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st751{fill:none;stroke:url(#SVGID_764_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st752{fill:none;stroke:url(#SVGID_765_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st753{fill:none;stroke:url(#SVGID_766_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st754{fill:none;stroke:url(#SVGID_767_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st755{fill:none;stroke:url(#SVGID_768_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st756{fill:none;stroke:url(#SVGID_769_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st757{fill:none;stroke:url(#SVGID_770_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st758{fill:none;stroke:url(#SVGID_771_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st759{fill:none;stroke:url(#SVGID_772_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st760{fill:none;stroke:url(#SVGID_773_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st761{fill:none;stroke:url(#SVGID_774_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st762{fill:none;stroke:url(#SVGID_775_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st763{fill:none;stroke:url(#SVGID_776_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st764{fill:none;stroke:url(#SVGID_777_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st765{fill:none;stroke:url(#SVGID_778_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st766{fill:none;stroke:url(#SVGID_779_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st767{fill:none;stroke:url(#SVGID_780_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st768{fill:none;stroke:url(#SVGID_781_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st769{fill:none;stroke:url(#SVGID_782_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st770{fill:none;stroke:url(#SVGID_783_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st771{fill:none;stroke:url(#SVGID_784_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st772{fill:none;stroke:url(#SVGID_785_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st773{fill:none;stroke:url(#SVGID_786_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st774{fill:none;stroke:url(#SVGID_787_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st775{fill:none;stroke:url(#SVGID_788_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st776{fill:none;stroke:url(#SVGID_789_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st777{fill:none;stroke:url(#SVGID_790_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st778{fill:none;stroke:url(#SVGID_791_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st779{fill:none;stroke:url(#SVGID_792_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st780{fill:none;stroke:url(#SVGID_793_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st781{fill:none;stroke:url(#SVGID_794_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st782{fill:none;stroke:url(#SVGID_795_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st783{fill:none;stroke:url(#SVGID_796_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st784{fill:none;stroke:url(#SVGID_797_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st785{fill:none;stroke:url(#SVGID_798_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st786{fill:none;stroke:url(#SVGID_799_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st787{fill:none;stroke:url(#SVGID_800_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st788{clip-path:url(#SVGID_802_${key});}
	.st789{fill:none;stroke:url(#SVGID_803_${key});stroke-width:2;stroke-miterlimit:10;}
	.st790{fill:none;stroke:url(#SVGID_804_${key});stroke-width:2;stroke-miterlimit:10;}
	.st791{fill:none;stroke:url(#SVGID_805_${key});stroke-width:2;stroke-miterlimit:10;}
	.st792{fill:none;stroke:url(#SVGID_806_${key});stroke-width:2;stroke-miterlimit:10;}
	.st793{fill:none;stroke:url(#SVGID_807_${key});stroke-width:2;stroke-miterlimit:10;}
	.st794{fill:none;stroke:url(#SVGID_808_${key});stroke-width:2;stroke-miterlimit:10;}
	.st795{fill:none;stroke:url(#SVGID_809_${key});stroke-width:2;stroke-miterlimit:10;}
	.st796{fill:none;stroke:url(#SVGID_810_${key});stroke-width:2;stroke-miterlimit:10;}
	.st797{fill:none;stroke:url(#SVGID_811_${key});stroke-width:2;stroke-miterlimit:10;}
	.st798{fill:none;stroke:url(#SVGID_812_${key});stroke-width:2;stroke-miterlimit:10;}
	.st799{display:none;}
	.st800{display:inline;fill:#DADADA;}
	.st801{fill:none;stroke:url(#SVGID_813_${key});stroke-width:2;stroke-miterlimit:10;}
	.st802{opacity:0.16;clip-path:url(#SVGID_815_${key});}
	.st803{fill:none;stroke:url(#SVGID_816_${key});stroke-width:2;stroke-miterlimit:10;}
	.st804{fill:none;stroke:url(#SVGID_817_${key});stroke-width:2;stroke-miterlimit:10;}
	.st805{fill:none;stroke:url(#SVGID_818_${key});stroke-width:2;stroke-miterlimit:10;}
	.st806{fill:none;stroke:url(#SVGID_819_${key});stroke-width:2;stroke-miterlimit:10;}
	.st807{fill:none;stroke:url(#SVGID_820_${key});stroke-width:2;stroke-miterlimit:10;}
	.st808{fill:none;stroke:url(#SVGID_821_${key});stroke-width:2;stroke-miterlimit:10;}
	.st809{fill:none;stroke:url(#SVGID_822_${key});stroke-width:2;stroke-miterlimit:10;}
	.st810{fill:none;stroke:url(#SVGID_823_${key});stroke-width:2;stroke-miterlimit:10;}
	.st811{fill:none;stroke:url(#SVGID_824_${key});stroke-width:2;stroke-miterlimit:10;}
	.st812{fill:none;stroke:url(#SVGID_825_${key});stroke-width:2;stroke-miterlimit:10;}
	.st813{fill:none;stroke:url(#SVGID_826_${key});stroke-width:2;stroke-miterlimit:10;}
	.st814{fill:none;stroke:url(#SVGID_827_${key});stroke-width:2;stroke-miterlimit:10;}
	.st815{fill:none;stroke:url(#SVGID_828_${key});stroke-width:2;stroke-miterlimit:10;}
	.st816{fill:none;stroke:url(#SVGID_829_${key});stroke-width:2;stroke-miterlimit:10;}
	.st817{fill:none;stroke:url(#SVGID_830_${key});stroke-width:2;stroke-miterlimit:10;}
	.st818{fill:none;stroke:url(#SVGID_831_${key});stroke-width:2;stroke-miterlimit:10;}
	.st819{fill:none;stroke:url(#SVGID_832_${key});stroke-width:2;stroke-miterlimit:10;}
	.st820{fill:none;stroke:url(#SVGID_833_${key});stroke-width:2;stroke-miterlimit:10;}
	.st821{fill:url(#SVGID_834_${key});}
	.st822{fill:url(#SVGID_835_${key});}
	.st823{opacity:0.07;clip-path:url(#SVGID_837_${key});}
	.st824{opacity:0.4;clip-path:url(#SVGID_837_${key});fill:url(#SVGID_838_${key});}
	.st825{fill:url(#SVGID_839_${key});}
	.st826{fill:url(#SVGID_840_${key});}
	.st827{fill:url(#SVGID_841_${key});}
	.st828{fill:url(#SVGID_842_${key});}
	.st829{clip-path:url(#SVGID_844_${key});}
	.st830{fill:none;stroke:url(#SVGID_845_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st831{fill:none;stroke:url(#SVGID_846_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st832{fill:none;stroke:url(#SVGID_847_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st833{fill:none;stroke:url(#SVGID_848_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st834{fill:none;stroke:url(#SVGID_849_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st835{fill:none;stroke:url(#SVGID_850_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st836{fill:none;stroke:url(#SVGID_851_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st837{fill:none;stroke:url(#SVGID_852_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st838{fill:none;stroke:url(#SVGID_853_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st839{fill:none;stroke:url(#SVGID_854_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st840{fill:none;stroke:url(#SVGID_855_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st841{fill:none;stroke:url(#SVGID_856_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st842{fill:none;stroke:url(#SVGID_857_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st843{fill:none;stroke:url(#SVGID_858_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st844{fill:none;stroke:url(#SVGID_859_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st845{fill:none;stroke:url(#SVGID_860_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st846{fill:none;stroke:url(#SVGID_861_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st847{fill:none;stroke:url(#SVGID_862_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st848{fill:none;stroke:url(#SVGID_863_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st849{fill:none;stroke:url(#SVGID_864_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st850{fill:none;stroke:url(#SVGID_865_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st851{fill:none;stroke:url(#SVGID_866_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st852{fill:none;stroke:url(#SVGID_867_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st853{fill:none;stroke:url(#SVGID_868_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st854{fill:none;stroke:url(#SVGID_869_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st855{fill:none;stroke:url(#SVGID_870_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st856{fill:none;stroke:url(#SVGID_871_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st857{fill:none;stroke:url(#SVGID_872_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st858{fill:none;stroke:url(#SVGID_873_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st859{fill:none;stroke:url(#SVGID_874_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st860{fill:none;stroke:url(#SVGID_875_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st861{fill:none;stroke:url(#SVGID_876_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st862{fill:none;stroke:url(#SVGID_877_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st863{fill:none;stroke:url(#SVGID_878_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st864{fill:none;stroke:url(#SVGID_879_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st865{fill:none;stroke:url(#SVGID_880_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st866{fill:none;stroke:url(#SVGID_881_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st867{fill:none;stroke:url(#SVGID_882_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st868{fill:none;stroke:url(#SVGID_883_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st869{fill:none;stroke:url(#SVGID_884_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st870{fill:none;stroke:url(#SVGID_885_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st871{fill:none;stroke:url(#SVGID_886_${key});stroke-width:0.5;stroke-miterlimit:10;}
	.st872{fill:none;stroke:url(#SVGID_887_${key});stroke-width:3;stroke-miterlimit:10;}
	.st873{fill:none;stroke:url(#SVGID_888_${key});stroke-width:3;stroke-miterlimit:10;}
	.st874{fill:none;stroke:url(#SVGID_889_${key});stroke-width:3;stroke-miterlimit:10;}
	.st875{fill:none;stroke:url(#SVGID_890_${key});stroke-width:3;stroke-miterlimit:10;}
	.st876{fill:none;stroke:url(#SVGID_891_${key});stroke-width:3;stroke-miterlimit:10;}
	.st877{fill:none;stroke:url(#SVGID_892_${key});stroke-width:3;stroke-miterlimit:10;}
	.st878{fill:none;stroke:url(#SVGID_893_${key});stroke-width:3;stroke-miterlimit:10;}
	.st879{fill:url(#SVGID_894_${key});}
	.st880{fill:none;stroke:url(#SVGID_895_${key});stroke-width:3;stroke-miterlimit:10;}
	.st881{clip-path:url(#SVGID_897_${key});}
	.st882{opacity:0.51;fill:none;stroke:url(#SVGID_898_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st883{opacity:0.51;fill:none;stroke:url(#SVGID_899_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st884{opacity:0.82;fill:none;stroke:url(#SVGID_900_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st885{opacity:0.51;fill:none;stroke:url(#SVGID_901_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st886{opacity:0.51;fill:none;stroke:url(#SVGID_902_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st887{opacity:0.51;fill:none;stroke:url(#SVGID_903_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st888{opacity:0.82;fill:none;stroke:url(#SVGID_904_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st889{opacity:0.51;fill:none;stroke:url(#SVGID_905_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st890{fill:url(#SVGID_906_${key});}
	.st891{fill:url(#SVGID_907_${key});stroke:url(#SVGID_908_${key});stroke-width:3;stroke-miterlimit:10;}
	.st892{clip-path:url(#SVGID_910_${key});}
	.st893{opacity:0.51;fill:none;stroke:url(#SVGID_911_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st894{opacity:0.51;fill:none;stroke:url(#SVGID_912_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st895{opacity:0.82;fill:none;stroke:url(#SVGID_913_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st896{opacity:0.51;fill:none;stroke:url(#SVGID_914_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st897{opacity:0.51;fill:none;stroke:url(#SVGID_915_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st898{opacity:0.51;fill:none;stroke:url(#SVGID_916_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st899{opacity:0.82;fill:none;stroke:url(#SVGID_917_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
	.st900{opacity:0.51;fill:none;stroke:url(#SVGID_918_${key});stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}`}
      </style>
      <g>
        <g>
          <defs>
            <path
              d="M-159.43,63.76c-71.91,20.99-111.11,18.18-111.11,18.18s-38.73,2.82-110.64-18.18
				c0,0,6.36,64.63-58.68,82.31c0,0,8.04,114.36,41.48,176.82s128.07,113.36,128.07,113.36s94.63-50.89,128.07-113.36
				s41.48-176.82,41.48-176.82C-165.8,128.38-159.43,63.76-159.43,63.76z"
              id={`SVGID_405_${key}`}
            />
          </defs>
          <clipPath id={`SVGID_1_${key}`}>
            <use style={{ overflow: 'visible' }} />
          </clipPath>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id={`SVGID_2_${key}`}
            x1="-538.8828"
            x2="27.7441"
            y1="223.8597"
            y2="223.8597"
          >
            <stop offset="0.0169" style={{ stopColor: '#719F77' }} />
            <stop offset="0.5" style={{ stopColor: '#00743C' }} />
            <stop offset="1" style={{ stopColor: '#004D27' }} />
          </linearGradient>
          <polygon
            points="27.74,113.92 -457.79,450.71 -538.88,333.8 
			-53.35,-2.99 		"
            style={{
              opacity: '0.4',
              clipPath: `url(#SVGID_1_${key})`,
              fill: `url(#SVGID_2_${key})`
            }}
          />
        </g>
      </g>
      <g>
        <g>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id={`SVGID_3_${key}`}
            x1="122.8201"
            x2="377.2383"
            y1="355.503"
            y2="101.0849"
          >
            <stop offset="5.618000e-03" style={{ stopColor: '#2E2757' }} />
            <stop offset="0.0433" style={{ stopColor: '#32295C' }} />
            <stop offset="0.3034" style={{ stopColor: '#4D367D' }} />
            <stop offset="0.5" style={{ stopColor: '#61438C' }} />
            <stop offset="0.6826" style={{ stopColor: '#5E4289' }} />
            <stop offset="0.7971" style={{ stopColor: '#563D81' }} />
            <stop offset="0.893" style={{ stopColor: '#483572' }} />
            <stop offset="0.978" style={{ stopColor: '#342B5E' }} />
            <stop offset="1" style={{ stopColor: '#2E2757' }} />
          </linearGradient>
          <path
            className="st3"
            d="M131.65,95.71c37.33-13.44,132.77-39.22,237.31,0.16c9.69,3.65,16.02,13.06,16.02,23.41V293
			c0,72.94-59.13,132.07-132.07,132.07h-5.82c-72.94,0-132.07-59.13-132.07-132.07V119.33C115.02,108.73,121.67,99.3,131.65,95.71z"
          />
        </g>
        <g>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id={`SVGID_4_${key}`}
            x1="132.9652"
            x2="367.3762"
            y1="346.2411"
            y2="111.8301"
          >
            <stop offset="5.618000e-03" style={{ stopColor: '#2E2757' }} />
            <stop offset="0.0433" style={{ stopColor: '#32295C' }} />
            <stop offset="0.3034" style={{ stopColor: '#4D367D' }} />
            <stop offset="0.5" style={{ stopColor: '#61438C' }} />
            <stop offset="0.6826" style={{ stopColor: '#5E4289' }} />
            <stop offset="0.7971" style={{ stopColor: '#563D81' }} />
            <stop offset="0.893" style={{ stopColor: '#483572' }} />
            <stop offset="0.978" style={{ stopColor: '#342B5E' }} />
            <stop offset="1" style={{ stopColor: '#2E2757' }} />
          </linearGradient>
          <path
            className="st4"
            d="M141.1,106.88c34.39-12.38,122.33-36.14,218.64,0.15c8.93,3.36,14.76,12.03,14.76,21.57v160.06
			c0,67.2-54.48,121.68-121.68,121.68h-5.36c-67.2,0-121.68-54.48-121.68-121.68V128.64C125.78,118.87,131.91,110.19,141.1,106.88z"
          />
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id={`SVGID_6_${key}`}
            x1="125.7822"
            x2="374.5054"
            y1="249.0347"
            y2="249.0347"
          >
            <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
            <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
            <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
            <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
            <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
            <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
            <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
            <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
            <stop offset="0.5699" style={{ stopColor: '#989398' }} />
            <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
          </linearGradient>
          <path
            d="M252.34,89.73c37.04,0,72.94,6.45,106.7,19.17c8.06,3.03,13.47,10.95,13.47,19.7v160.06
			c0,31.97-12.45,62.02-35.05,84.63s-52.66,35.05-84.63,35.05h-5.36c-31.97,0-62.02-12.45-84.63-35.05s-35.05-52.66-35.05-84.63
			V128.64c0-8.88,5.62-16.87,13.99-19.88C161.51,101.66,202.12,89.73,252.34,89.73L252.34,89.73 M252.33,87.73
			c-50.01,0-90.42,11.66-111.23,19.15c-9.19,3.31-15.31,11.99-15.31,21.76v160.01c0,67.2,54.48,121.68,121.68,121.68h5.36
			c67.2,0,121.68-54.48,121.68-121.68V128.6c0-9.54-5.83-18.21-14.76-21.57C321.7,92.7,284.97,87.73,252.33,87.73L252.33,87.73z"
            style={{ fill: `url(#SVGID_6_${key})` }}
          />
        </g>
        <g>
          <defs>
            <path
              d="M138.56,109.51c33.44-12.32,123.26-35.05,218.93,0.17c10.76,3.96,12.3,11.83,12.3,21.26v158.21
				c0,66.43-53.01,120.28-118.4,120.28h-5.22c-65.39,0-118.4-53.85-118.4-120.28V130.98C127.78,119.5,128.91,113.06,138.56,109.51z"
              id={`SVGID_412_${key}`}
            />
          </defs>
          <clipPath id={`SVGID_7_${key}`}>
            <use style={{ overflow: 'visible' }} />
          </clipPath>
          <g style={{ clipPath: `url(#SVGID_7_${key})` }}>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_8_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="81.3122"
                y2="81.3122"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_8_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="81.31"
                y2="81.31"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_9_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="89.5401"
                y2="89.5401"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_9_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="89.54"
                y2="89.54"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_11_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="97.768"
                y2="97.768"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_11_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="97.77"
                y2="97.77"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_12_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="105.9958"
                y2="105.9958"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_12_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="106"
                y2="106"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_13_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="114.2237"
                y2="114.2237"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_13_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="114.22"
                y2="114.22"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_14_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="122.4516"
                y2="122.4516"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_14_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="122.45"
                y2="122.45"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_15_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="130.6794"
                y2="130.6794"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_15_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="130.68"
                y2="130.68"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_16_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="138.9073"
                y2="138.9073"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_16_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="138.91"
                y2="138.91"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_17_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="147.1352"
                y2="147.1352"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_17_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="147.14"
                y2="147.14"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_18_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="155.363"
                y2="155.363"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_18_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="155.36"
                y2="155.36"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_19_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="163.5909"
                y2="163.5909"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_19_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="163.59"
                y2="163.59"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_20_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="171.8188"
                y2="171.8188"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_20_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="171.82"
                y2="171.82"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_21_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="180.0466"
                y2="180.0466"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_21_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="180.05"
                y2="180.05"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_22_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="188.2745"
                y2="188.2745"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_22_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="188.27"
                y2="188.27"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_23_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="196.5024"
                y2="196.5024"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_23_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="196.5"
                y2="196.5"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_24_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="204.7303"
                y2="204.7303"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_24_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="204.73"
                y2="204.73"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_25_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="212.9581"
                y2="212.9581"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_25_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="212.96"
                y2="212.96"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_26_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="221.186"
                y2="221.186"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_26_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="221.19"
                y2="221.19"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_27_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="229.4139"
                y2="229.4139"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_27_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="229.41"
                y2="229.41"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_28_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="237.6417"
                y2="237.6417"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_28_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="237.64"
                y2="237.64"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_29_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="245.8696"
                y2="245.8696"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_29_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="245.87"
                y2="245.87"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_30_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="254.0975"
                y2="254.0975"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_30_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="254.1"
                y2="254.1"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_31_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="262.3253"
                y2="262.3253"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_31_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="262.33"
                y2="262.33"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_32_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="270.5532"
                y2="270.5532"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_32_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="270.55"
                y2="270.55"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_33_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="278.7811"
                y2="278.7811"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_33_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="278.78"
                y2="278.78"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_34_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="287.0089"
                y2="287.0089"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_34_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="287.01"
                y2="287.01"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_35_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="295.2368"
                y2="295.2368"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_35_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="295.24"
                y2="295.24"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_36_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="303.4647"
                y2="303.4647"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_36_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="303.46"
                y2="303.46"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_37_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="311.6926"
                y2="311.6926"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_37_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="311.69"
                y2="311.69"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_38_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="319.9204"
                y2="319.9204"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_38_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="319.92"
                y2="319.92"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_39_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="328.1483"
                y2="328.1483"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_39_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="328.15"
                y2="328.15"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_40_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="336.3762"
                y2="336.3762"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_40_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="336.38"
                y2="336.38"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_41_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="344.604"
                y2="344.604"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_41_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="344.6"
                y2="344.6"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_42_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="352.8319"
                y2="352.8319"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_42_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="352.83"
                y2="352.83"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_43_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="361.0598"
                y2="361.0598"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_43_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="361.06"
                y2="361.06"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_44_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="369.2876"
                y2="369.2876"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_44_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="369.29"
                y2="369.29"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_45_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="377.5155"
                y2="377.5155"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_45_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="377.52"
                y2="377.52"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_46_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="385.7434"
                y2="385.7434"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_46_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="385.74"
                y2="385.74"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_47_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="393.9713"
                y2="393.9713"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_47_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="393.97"
                y2="393.97"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_48_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="402.1991"
                y2="402.1991"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_48_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="402.2"
                y2="402.2"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_49_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="410.427"
                y2="410.427"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_49_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="410.43"
                y2="410.43"
              />
            </g>
            <g>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id={`SVGID_50_${key}`}
                x1="118.5332"
                x2="378.6189"
                y1="418.6548"
                y2="418.6548"
              >
                <stop offset="0" style={{ stopColor: '#885591' }} />
                <stop offset="0.1978" style={{ stopColor: '#8A5893' }} />
                <stop offset="0.327" style={{ stopColor: '#8F6099' }} />
                <stop offset="0.4362" style={{ stopColor: '#996FA3' }} />
                <stop offset="0.5" style={{ stopColor: '#A17CAC' }} />
                <stop offset="0.5348" style={{ stopColor: '#926B9E' }} />
                <stop offset="0.6056" style={{ stopColor: '#784F87' }} />
                <stop offset="0.682" style={{ stopColor: '#643975' }} />
                <stop offset="0.7656" style={{ stopColor: '#562A69' }} />
                <stop offset="0.8619" style={{ stopColor: '#4E2161' }} />
                <stop offset="1" style={{ stopColor: '#4B1E5F' }} />
              </linearGradient>

              <line
                style={{
                  fill: 'none',
                  stroke: `url(#SVGID_50_${key})`,
                  strokeWidth: 0.5,
                  strokeMiterlimit: 10
                }}
                x1="118.53"
                x2="378.62"
                y1="418.65"
                y2="418.65"
              />
            </g>
          </g>
        </g>
        <rect
          className="st447"
          height="97.36"
          width="120.7"
          x="188.54"
          y="192.2"
        />
        <text
          className="st10 st448 st449 st450"
          style={{ textAnchor: 'middle' }}
          transform="matrix(1 0 0 1 249 260)"
        >
          {level}
        </text>
        <rect
          className="st447"
          height="37.12"
          width="266.61"
          x="117.41"
          y="293.28"
        />
        <text
          className="st13 st451 st452 st453"
          style={{ textAnchor: 'middle' }}
          transform="matrix(1 0 0 1 250 312.2803)"
        >
          WORKOUT{level > 1 ? 'S' : ''}
        </text>
        <g>
          <defs>
            <path
              d="M140.84,106.98c34.39-12.38,122.33-36.14,218.64,0.15c8.93,3.36,14.76,12.03,14.76,21.57v160.06
				c0,67.2-54.48,121.68-121.68,121.68h-5.36c-67.2,0-121.68-54.48-121.68-121.68V128.75C125.53,118.98,131.65,110.29,140.84,106.98
				z"
              id={`SVGID_456_${key}`}
            />
          </defs>
          <clipPath id={`SVGID_51_${key}`}>
            <use style={{ overflow: 'visible' }} />
          </clipPath>
          <g style={{ clipPath: `url(#SVGID_51_${key})` }}>
            <linearGradient
              gradientTransform="matrix(0.8482 -9.603923e-03 -9.603923e-03 0.8043 1198.2393 2210.1782)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_52_${key}`}
              x1="-1160.7863"
              x2="-1077.59"
              y1="-2608.2004"
              y2="-2608.2004"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_52_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="238.91"
              x2="309.12"
              y1="163.87"
              y2="82.33"
            />

            <linearGradient
              gradientTransform="matrix(0.8482 -9.603923e-03 -9.603923e-03 0.8043 1198.2393 2210.1782)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_53_${key}`}
              x1="-1132.4552"
              x2="-1050.9067"
              y1="-2604.7844"
              y2="-2604.7844"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_53_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="262.92"
              x2="331.71"
              y1="165.53"
              y2="85.64"
            />

            <linearGradient
              gradientTransform="matrix(0.8482 -9.603923e-03 -9.603923e-03 0.8043 1198.2393 2210.1782)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_54_${key}`}
              x1="-1103.0812"
              x2="-1025.9774"
              y1="-2601.9302"
              y2="-2601.9302"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_54_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="287.83"
              x2="352.8"
              y1="165.35"
              y2="89.89"
            />

            <linearGradient
              gradientTransform="matrix(0.8482 -9.603923e-03 -9.603923e-03 0.8043 1198.2393 2210.1782)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_55_${key}`}
              x1="-1073.2889"
              x2="-1001.215"
              y1="-2598.3054"
              y2="-2598.3054"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_55_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="313.09"
              x2="373.74"
              y1="165.49"
              y2="95.06"
            />

            <linearGradient
              gradientTransform="matrix(0.8482 -9.603923e-03 -9.603923e-03 0.8043 1198.2393 2210.1782)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_56_${key}`}
              x1="-1044.2441"
              x2="-981.223"
              y1="-2592.2124"
              y2="-2592.2124"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_56_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="337.72"
              x2="390.59"
              y1="165.63"
              y2="104.24"
            />
          </g>
          <g style={{ clipPath: `url(#SVGID_51_${key})` }}>
            <linearGradient
              gradientTransform="matrix(-0.8482 9.603923e-03 9.603923e-03 -0.8043 12971.4199 20104.4941)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_57_${key}`}
              x1="15242.7793"
              x2="15325.9756"
              y1="24698.9199"
              y2="24698.9199"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_57_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="279.83"
              x2="209.62"
              y1="344.69"
              y2="426.23"
            />

            <linearGradient
              gradientTransform="matrix(-0.8482 9.603923e-03 9.603923e-03 -0.8043 12971.4199 20104.4941)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_58_${key}`}
              x1="15271.1104"
              x2="15352.6592"
              y1="24702.3359"
              y2="24702.3359"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_58_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="255.82"
              x2="187.03"
              y1="343.03"
              y2="422.92"
            />

            <linearGradient
              gradientTransform="matrix(-0.8482 9.603923e-03 9.603923e-03 -0.8043 12971.4199 20104.4941)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_59_${key}`}
              x1="15300.4844"
              x2="15377.5879"
              y1="24705.1914"
              y2="24705.1914"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_59_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="230.91"
              x2="165.94"
              y1="343.21"
              y2="418.67"
            />

            <linearGradient
              gradientTransform="matrix(-0.8482 9.603923e-03 9.603923e-03 -0.8043 12971.4199 20104.4941)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_60_${key}`}
              x1="15330.2764"
              x2="15402.3506"
              y1="24708.8164"
              y2="24708.8164"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_60_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="205.65"
              x2="145"
              y1="343.07"
              y2="413.5"
            />

            <linearGradient
              gradientTransform="matrix(-0.8482 9.603923e-03 9.603923e-03 -0.8043 12971.4199 20104.4941)"
              gradientUnits="userSpaceOnUse"
              id={`SVGID_61_${key}`}
              x1="15359.3213"
              x2="15422.3428"
              y1="24714.9082"
              y2="24714.9082"
            >
              <stop offset="0" style={{ stopColor: '#EEEBE9' }} />
              <stop offset="0" style={{ stopColor: '#E7E3E0' }} />
              <stop offset="0" style={{ stopColor: '#F1F0EF' }} />
              <stop offset="0" style={{ stopColor: '#F8F8F8' }} />
              <stop offset="0.0451" style={{ stopColor: '#E9E9E9' }} />
              <stop offset="0.1313" style={{ stopColor: '#C2C2C2' }} />
              <stop offset="0.1642" style={{ stopColor: '#B2B2B2' }} />
              <stop offset="0.3241" style={{ stopColor: '#B1B0B1' }} />
              <stop offset="0.5699" style={{ stopColor: '#989398' }} />
              <stop offset="0.9941" style={{ stopColor: '#8C878A' }} />
            </linearGradient>

            <line
              style={{
                fill: 'none',
                stroke: `url(#SVGID_61_${key})`,
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              x1="181.01"
              x2="128.15"
              y1="342.92"
              y2="404.32"
            />
          </g>
        </g>
        <g>
          <circle className="st13" cx="325.37" cy="352.2" r="3.72" />
          <circle className="st13" cx="154.64" cy="136.46" r="3.72" />
        </g>
      </g>
    </svg>
  )
}
